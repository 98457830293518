export default {
  getNewUserProfileId(state) {
    return state.newUserProfileId;
  },
  getUser(state) {
    return state.user;
  },
  getJobPostings(state) {
    return state.jobPostings;
  },
  getHiringRequestApplicants(state) {
    return state.hiringRequestApplicants;
  },
  getOffers(state) {
    return state.offers;
  },
  getUpcomingInterviews(state) {
    return state.upcomingInterviews;
  },
  getFirstInterviews(state) {
    return state.firstInterviews;
  },
  getSecondInterviews(state) {
    return state.secondInterviews;
  },
  getPastInterviews(state) {
    return state.pastInterviews;
  },
  // getEscalatedHiringRequest(state) {
  //   return state.escalated_hiring_request;
  // },
  // getDeclinedHiringRequest(state) {
  //   return state.declined_hiring_request;
  // },
  // getHiringRequest(state) {
  //   return state.hiring_request;
  // },
  // getSingleDepartment(state) {
  //   return state.singleDepartment;
  // },
};
