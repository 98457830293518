export default {
  setNewUserProfileId(state, payload) {
    state.newUserProfileId = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setJobPostings(state, payload) {
    state.jobPostings = payload;
  },
  setOffers(state, payload) {
    state.offers = payload.data;
  },
  setUpcomingInterviews(state, payload) {
    state.upcomingInterviews = payload;
  },
  setFirstInterviews(state, payload) {
    state.firstInterviews = payload;
  },
  setSecondInterviews(state, payload) {
    state.secondInterviews = payload;
  },
  setHiringRequestApplicants(state, payload) {
    state.hiringRequestApplicants = payload;
  },
  setPastInterviews(state, payload) {
    state.pastInterviews = payload;
  },
};
