export default {
  setPatientSurvey (state, payload) {
    state.patientSurvey = payload
  },
  setPatientSurveys (state, payload) {
    state.patientSurveys = payload
  },
  setSurveyAnswers (state, payload) {
    state.answers = payload
  },
  setSurveyFeedbacks (state, payload) {
    state.feedbacks = payload
  },
  setQuestionsAndAnswers(state, payload) {
    state.questions = payload
  },
  setPractices(state, payload) {
    state.practices = payload;
  },
  setQuestionStats(state, payload) {
    state.questionStats = payload
  },
  setOptionStats(state, payload) {
    state.optionStats = payload;
  },
  setSurveyCount(state, payload) {
    state.surveyCount = payload;
  }
}
