/** @format */

import { POSTCallHelper, GETCallHelper } from "../../../services";
import { handleError } from "../../../errorServices";

export default {
  async submitCompliance({ commit }, payload) {
    try {
      const response = await POSTCallHelper(
        "/ma/compliance-calendar-items/upload-files",
        payload
      );
      if (response.data["success"])
        commit(
          "updateFileSubmission",
          response.data["compliance-files-submition"]
        );
    } catch (e) {
      handleError(e);
    }
  },

  async fetchAllCompliances({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/ma/compliance-calendar-items?practice=${payload.practiceId}`
      );
      commit("setAllCompliances", response.data["compliance-calendar-items"]);
    } catch (e) {
      handleError(e);
    }
  },

  async fetchSingleCompliances({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/ma/compliance-calendar-items/compliance-calendar?compliance_calendar_item=${payload.complianceId}&practice=${payload.practiceId}`
      );
      commit("setSingleCompliance", response.data["compliance-calendar-item"]);
    } catch (e) {
      handleError(e);
    }
  },

  async policyReviewd({ commit }, payload) {
    try {
      const response = await POSTCallHelper(
        `/ma/compliance-calendar-items/policy-review?compliance_item=${payload.complianceId}&practice=${payload.practiceId}`
      );
      if (response.data["success"]) commit("updateStatus", 4);
    } catch (e) {
      handleError(e);
    }
  },
};
