import {
  GETLOADING,
  GET_MY_COURSES,
  GET_CURRENT_COURSE_GETTER,
  GET_MY_POLICIES
} from './constants'

export default {
  [GETLOADING] (state) {
    return state.loading
  },
  [GET_MY_COURSES] (state) {
    return state.my_courses
  },
  [GET_CURRENT_COURSE_GETTER] (state) {
    return state.current_course
  },
  [GET_MY_POLICIES] (state) {
    return state.user_policies
  }
}
