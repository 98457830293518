/** @format */

const ComplianceDashboard = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Compliance/ComplianceDashboard.vue"
  );

const CreateCompliance = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Compliance/CreateCompliance.vue"
  );

const CompliancePracticesResponse = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Compliance/CompliancePracticesResponse.vue"
  );

const ArchivedCompliances = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Compliance/ArchivedCompliances.vue"
  );

const SingleComplianceDetails = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Compliance/SingleComplianceDetails.vue"
  );

const SingleComplianceBySinglePractice = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Compliance/SingleComplianceBySinglePractice.vue"
  );

const hqComplianceRoutes = [
  {
    path: "",
    name: "hq-monitoringOfPracticeFunctionsComplianceDashboard",
    component: ComplianceDashboard,
  },
  {
    path: "monitoringOfPracticeFunctionsCreateCompliance",
    name: "hq-monitoringOfPracticeFunctionsCreateCompliance",
    component: CreateCompliance,
  },
  {
    path: "monitoringOfPracticeFunctionsComplianceResponses/compliance/:complianceId",
    name: "hq-monitoringOfPracticeFunctionsComplianceResponses",
    component: CompliancePracticesResponse,
  },
  {
    path: "monitoringOfPracticeFunctionsComplianceResponses/compliance/:complianceId/practice/:practiceId",
    name: "hq-monitoringOfPracticeFunctionsComplianceResponses-BySinglePractice",
    component: SingleComplianceBySinglePractice,
  },
  {
    path: "monitoringOfPracticeFunctionsArchivedCompliances/type/:complianceType",
    name: "hq-monitoringOfPracticeFunctionsArchivedCompliances",
    component: ArchivedCompliances,
  },
  {
    path: "monitoringOfPracticeFunctionsComplianceResponses/compliance/:complianceId/singleComplianceDetails/:isArchived",
    name: "hq-monitoringOfPracticeFunctionsSingleComplianceDetails",
    component: SingleComplianceDetails,
  },
];

export default hqComplianceRoutes;
