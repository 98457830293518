import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      courses: [],
      enableLessons: false,
      currentCourse: null,
      coursesTotal: 0
    }
  },
  mutations,
  actions,
  getters
}
