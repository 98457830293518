// import axios from "axios";
import { handleError } from "../errorServices";
import {
  GETCallHelper,
  POSTCallHelper,
  DELETECallHelper,
} from "../services.js";
export default {
  // Get Practice
  async loadPractices(context, payload) {
    try {
      const response = await GETCallHelper("practices", payload);
      context.commit("setPractices", response.data.practices);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Delete Practice
  async deletePractice(context, payload) {
    try {
      await DELETECallHelper("practices/delete/", payload);
      context.commit("deletePractice", payload);
      context.dispatch("loadPractices");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Add Practice
  async addPractice(context, payload) {
    try {
      await POSTCallHelper("practices/create", payload);
      context.dispatch("loadPractices");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Assign practice to user
  async assignUserToPractice(context, payload) {
    try {
      await POSTCallHelper("practices/assign-to-user", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Remove user from practice
  async removeUserFromPractice(context, payload) {
    try {
      await POSTCallHelper("practices/revoke-for-user", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Get Policies
  async loadPolicies(context, payload) {
    try {
      const response = await GETCallHelper("policies", payload);
      context.commit("setPolicies", response.data.policies);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Add Policy
  async addPolicy(context, payload) {
    try {
      await POSTCallHelper("policies/create", payload);
      context.dispatch("loadPolicies");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Delete Policy
  async deletePolicy(context, payload) {
    try {
      await DELETECallHelper("policies/delete/", payload);
      context.commit("deletePolicy", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Get Users
  async loadUsers(context, payload) {
    try {
      const response = await POSTCallHelper("users", payload);
      context.commit("setUser", response.data.users);
      return response.data.users;
    } catch (e) {
      handleError(e.response.data);
      return false;
    }
  },
  // Add User
  async addUser(context, payload) {
    try {
      await POSTCallHelper("users/create", payload);
      context.dispatch("loadUsers");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Delete User
  async deleteUser(context, payload) {
    try {
      await DELETECallHelper("users/delete/", payload);
      context.commit("deleteUser", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Get Roles
  async loadRoles(context, payload) {
    try {
      // const response = await GETCallHelper("roles", payload);
      const response = payload;
      var filteredArr = [];
      var roleData = [];
        if (response.data?.roles.length !== 0) {
          response.data?.roles.forEach((item) => {
            item.name = item.name[0].toUpperCase() + item.name.substring(1);
            const result = item.name.replaceAll("_", " ");
            roleData.push({ code: item.id, label: result });
          });
          filteredArr = roleData.reduce((acc, current) => {
            const x = acc.find(item => item.code === current.code);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
        }
        if(filteredArr.length > 0){
          filteredArr = filteredArr.filter(item => !['Admin', 'Recruiter', 'Manager', 'Hq', 'Super admin'].includes(item.label));
        }
      context.commit("setRoles", filteredArr);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Add Role
  async addRole(context, payload) {
    try {
      await POSTCallHelper("roles/create", payload);
      context.dispatch("loadRoles");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Delete Role
  async deleteRole(context, payload) {
    try {
      await DELETECallHelper("roles/delete/", payload);
      context.commit("deleteRole", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Assign role to user
  async assignRoleToUser(context, payload) {
    try {
      await POSTCallHelper("roles/assign", payload);
      context.dispatch("loadRoles");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Remove role from user
  async removeRoleFromUser(context, payload) {
    try {
      await POSTCallHelper("roles/revoke", payload);
      context.dispatch("loadRoles");
    } catch (e) {
      handleError(e.response.data);
    }
  },

  // Get Permissions
  async loadPermissions(context, payload) {
    try {
      const response = await GETCallHelper("permissions", payload);
      context.commit("setPermissions", response.data.permissions);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Add Permissions
  async addPermission(context, payload) {
    try {
      await POSTCallHelper("permissions/create", payload);
      context.dispatch("loadPermissions");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Delete Permissions
  async deletePermission(context, payload) {
    try {
      await DELETECallHelper("permissions/delete/", payload);
      context.commit("deletePermissions", payload);
      context.dispatch("loadPermissions");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Assign permission to user
  async assignPermissionToUser(context, payload) {
    try {
      await POSTCallHelper("permissions/assign-to-user", payload);
      context.dispatch("loadPermissions");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Remove permission from user
  async removePremissionFromUser(context, payload) {
    try {
      await POSTCallHelper("permissions/revoke-for-user", payload);
      context.dispatch("loadPermissions");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Assign permission to role
  async assignPermissionToRole(context, payload) {
    try {
      await POSTCallHelper("permissions/assign-to-role", payload);
      context.dispatch("loadPermissions");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Remove permission from user
  async removePermissionFromRole(context, payload) {
    try {
      await POSTCallHelper("permissions/revoke-for-role", payload);
      context.dispatch("loadPermissions");
    } catch (e) {
      handleError(e.response.data);
    }
  },

  // Get Rooms
  async loadRooms(context, payload) {
    try {
      const response = await POSTCallHelper("rooms", {}, payload);
      context.commit("setRooms", response.data.rooms);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Add Room
  async addRoom(context, payload) {
    try {
      await POSTCallHelper("rooms/create", payload);
      context.dispatch("loadRooms");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Delete Rooms
  async deleteRoom(context, payload) {
    try {
      await DELETECallHelper("rooms/delete/", payload);
      context.commit("deleteRooms", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Get Reason
  async loadReasons(context, payload) {
    try {
      const response = await GETCallHelper("reasons", payload);
      context.commit("setReasons", response.data.reasons);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Add Reason
  async addReason(context, payload) {
    try {
      await POSTCallHelper("reasons/create", payload);
      context.dispatch("loadReasons");
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Delete Reason
  async deleteReason(context, payload) {
    try {
      await DELETECallHelper("reasons/delete/", payload);
      context.commit("deleteReasons", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },
};
