import { handleError } from '../../errorServices'
import { POSTCallHelper, PATCHCallHelper } from '../../services.js'

export default {
  // Create User
  async recruiterCreateUser (context, payload) {
    try {
      // let updateUserProfile = payload[1];
      const response = await POSTCallHelper('users/create', payload)
      //Use response to add profile ID to created USER
      context.commit('setNewUserProfileId', response.data.user.profile.id)
      // payload[1].profile = response.data.user.profile.id;
      // await context.dispatch("recruiterUpdateProfile", payload[1]);
    } catch (e) {
      console.log('error inside create', e.response.data)
      handleError(e.response.data)
    }
  },
  // Update user Profile
  async recruiterUpdateProfile (context, payload) {
    try {
      const response = await PATCHCallHelper('profiles/update', payload)
      console.log('updateProfile - RESPONSE: ', response.data)
    } catch (e) {
      return handleError(e.response.data)
    }
  },
  // FETCH Profile Data
  async fetchUserData (context, payload) {
    try {
      const response = await POSTCallHelper('users/user', payload)
      context.commit('setUser', response.data.user)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Job Postings
  async fetchJobPostings (context, payload) {
    try {
      const response = await POSTCallHelper('hiring-requests/postings', payload)
      context.commit('setJobPostings', response.data.postings)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Hiring Requests Applicants
  async fetchHiringRequestApplicants (context, [payload, page]) {
    try {
      const response = await POSTCallHelper(
        'hiring-requests/applicants',
        payload,
        page
      )
      context.commit('setHiringRequestApplicants', response.data.applicants)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Create User
  async createJobPosting (_, payload) {
    try {
      const response = await POSTCallHelper(
        'hiring-requests/postings/create',
        payload
      )
      console.log('create job posting response : ', response)
    } catch (e) {
      console.log('error inside create job posting', e.response.data)
      handleError(e.response.data)
    }
  },
  // hire candidate
  async hireCandidate (_, payload) {
    try {
      const response = await POSTCallHelper('re/candidates/hire', payload)
      return response
    } catch (e) {
      console.log('error inside create job posting', e.response.data)
      handleError(e.response.data)
    }
  },
  // Fetch Offers
  async fetchAllOffers (context, payload) {
    try {
      const response = await POSTCallHelper('hq/offers', payload)
      context.commit('setOffers', response.data.offers)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Create Offer
  async createOffer (_, payload) {
    try {
      const response = await POSTCallHelper('re/offers/create', payload)
      console.log('create Offer response : ', response)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Update Offer
  async updateOffer (_, payload) {
    try {
      const response = await PATCHCallHelper('re/offers/update', payload)
      console.log('Update Offer : ', response)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  //ammend offer
  async ammendOffer (_, payload) {
    try {
      const response = await POSTCallHelper(
        're/offers/amendments/create',
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // update ammendment
  async updateAmmendment (_, payload) {
    try {
      const response = await PATCHCallHelper(
        're/offers/amendments/update',
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Person Specifications
  async updateHiringRequest (context, payload) {
    try {
      await POSTCallHelper('hiring-requests/update', payload)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Fetch Upcoming Interviews
  async fetchUpcomingInterviews (context) {
    try {
      const response = await POSTCallHelper('hq/interviews/up-coming')
      context.commit(
        'setUpcomingInterviews',
        response.data['interview-schedules'].data
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchFirstInterviews (context, payload) {
    try {
      const response = await POSTCallHelper(
        're/interviews/all-interviews',
        payload
      )
      context.commit('setFirstInterviews', response.data['interviews'].data)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchSecondInterviews (context, payload) {
    try {
      const response = await POSTCallHelper(
        're/interviews/all-interviews',
        payload
      )
      context.commit('setSecondInterviews', response.data['interviews'].data)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchPastInterviews (context) {
    try {
      const response = await POSTCallHelper('re/interviews/past-interviews')
      context.commit(
        'setPastInterviews',
        response.data['interview-schedules'].data
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  }
}
