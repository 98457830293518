import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      practiceInterviews: null,
      all_inductions: [],
      practice: null,
      induction_policies: [],
      current_induction: null
    }
  },
  mutations,
  actions,
  getters
}
