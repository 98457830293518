export default {
  setLoading (state, payload) {
    state.loading = payload
  },
  setPracticeInterviews (state, payload) {
    state.practiceInterviews = payload
  },
  setInductionsSuccess (state, payload) {
    state.all_inductions = payload
    state.total_induction_scheduled = Math.ceil(payload.total / 10) * 10
  },
  setInductionsUnSuccess (state) {
    state.all_inductions = []
  },
  setPracticeID (state, payload) {
    state.practice = payload
  },
  setInductionPolicies (state, payload) {
    state.induction_policies = payload
    state.total_induction_policies = Math.ceil(payload.total / 10) * 10
  },
  setCurrentInduction (state, payload) {
    state.current_induction = payload
  },
  ['set-single-induction-checklist'] (state, payload) {
    state.current_induction_checklist = payload
  }
}
