export default {
  getNewHiringRequest(state) {
    return state.newHiringRequest;
  },
  getPracticeDepartments(state) {
    return state.practiceDepartments;
  },
  getJobSpecifications(state) {
    return state.jobSpecifications;
  },
  getSingleJobSpecification(state) {
    return state.singleJobSpecifications;
  },
  getPersonSpecifications(state) {
    return state.personSpecifications;
  },
  getSinglePersonSpecification(state) {
    return state.singlePersonSpecification;
  },
  getNewApplicationsHiringRequest(state) {
    return state.new_applications_hiring_request;
  },
  getApprovedHiringRequest(state) {
    return state.approved_hiring_request;
  },
  getEscalatedHiringRequest(state) {
    return state.escalated_hiring_request;
  },
  getDeclinedHiringRequest(state) {
    return state.declined_hiring_request;
  },
};
