// actions
export const SETTUTORIALACTION = 'SETTUTORIALACTION'
export const FETCH_MY_COURSES_ACTION = 'FETCH_MY_COURSES_ACTION'
export const FETCH_SINGLE_COURSE_ACTION = 'FETCH_SINGLE_COURSE_ACTION'
export const UPDATE_MODULE_STATUS_ACTION = 'UPDATE_MODULE_STATUS_ACTION'
export const UPDATE_LESSON_STATUS_ACTION = 'UPDATE_LESSON_STATUS_ACTION'
export const FETCH_MY_POLICIES_ACTION = 'FETCH_MY_POLICIES_ACTION'

// mutations
export const SETLOADINGMUTATION = 'SETLOADINGMUTATION'
export const FETCH_MY_COURSES_MUTATION = 'FETCH_MY_COURSES_MUTATION'
export const SET_CURRENT_COURSE_MUTATION = 'SET_CURRENT_COURSE_MUTATION'
export const FETCH_MY_POLICIES_MUTATION = 'FETCH_MY_POLICIES_MUTATION'
//getters

export const GETLOADING = 'GETLOADING'
export const GET_MY_COURSES = 'GET_MY_COURSES'
export const GET_CURRENT_COURSE_GETTER = 'GET_CURRENT_COURSE_GETTER'
export const GET_MY_POLICIES = 'GET_MY_POLICIES'
