export default {
    getActiveInterview(state){
        return state.activeInterview
    },
    getadhocQuestions(state){
        return state.adhocQuestions
    },
    getcandidateQuestions(state){
        return state.candidateQuestions
    },
    getMiscInformation(state){
       return state.miscInformation
    },
    getErrors(state){
        return state.errors
    }
}