export default {
  setNewApplicationsHiringRequest (state, payload) {
    state.new_applications_hiring_request = payload
  },
  setApprovedHiringRequest (state, payload) {
    state.approved_hiring_request = payload
  },
  setEscalatedHiringRequest (state, payload) {
    state.escalated_hiring_request = payload
  },
  setDeclinedHiringRequest (state, payload) {
    state.declined_hiring_request = payload
  },
  setHiringRequest (state, payload) {
    state.hiring_request = payload
  },
  setSingleDepartment (state, payload) {
    state.singleDepartment = payload
  },
  setUpcomingInterviews (state, payload) {
    state.upcomingInterviews = payload
  },
  setAllInterviews (state, payload) {
    state.allInterviews = payload
  },
  setAllOffers (state, payload) {
    state.allOffers = payload
  },
  setVacanciesFilter (state, payload) {
    state.vacancies_filtered = payload
  },
  setCandidates (state, payload) {
    state.candidates = payload
  },
  setPractices(state, payload) {
    state.practices = payload
  },
}
