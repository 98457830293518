export default {
  setIndicators(state, payload) {
    state.indicators = payload
  },
  setIndicatorsWithStats(state, payload) {
    state.indicatorsWithStats = payload
  },
  setSingleIndicatorStats(state, payload) {
    state.singleIndicatorStats = payload;
  },
  setQofEntries(state, payload) {
    state.qofEntries = payload
  },
  setSingleQofEntry(state, payload) {
    state.singleQofEntry = payload;
  },
  setSubIndicatorStats(state, payload) {
    state.subIndicatorStats = payload;
  },
  setAverageByMonth(state, payload) {
    state.averageByMonth = payload;
  }
}