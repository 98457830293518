/** @format */

const HqComplaintsDashboard = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Complaints/HqComplaintsDashboard.vue"
  );

const HqCreateEditViewComplaint = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Complaints/HqCreateEditViewComplaint.vue"
  );

const hqComplaintsRoutes = [
  {
    path: "",
    name: "hq-monitoringOfPracticeFunctionComplaintsDashboard",
    component: HqComplaintsDashboard,
  },
  {
    path: "/:isManager/create/:isEnabled/complaint/:complaintId",
    name: "hq-monitoringOfPracticeFunctionsCreateComplaints",
    component: HqCreateEditViewComplaint,
  },
];

export default hqComplaintsRoutes;
