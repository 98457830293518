import { handleError } from '../../errorServices'
import { POSTCallHelper, GETCallHelper } from '../../services.js'

export default {
  async createSession (context, payload) {
    try {
      await POSTCallHelper('/re/locums/sessions/create', payload)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSessions (context, payload = {}) {
    try {
      const response = await POSTCallHelper(
        `re/locums/sessions?page=${payload.page}`,
        payload
      )
      const sessions = response?.data?.['locum-sessions']
      context.commit('setSessions', sessions)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchMonthlySessions (context, payload = {}) {
    try {
      const response = await POSTCallHelper('us/locum/sessions/month', payload)
      //console.log(response.data)
      const sessions = response?.data?.['sessions-by-month']
      context.commit('setMonthlySessions', sessions)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchTodaysSessions (context, payload = {}) {
    try {
      const response = await POSTCallHelper('us/locum/sessions/day', payload)
      //console.log(response.data)
      const sessions = response?.data?.['sessions-by-day']
      context.commit('setTodaysSessions', sessions)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchLocumProfiles (context, payload) {
    console.log('Payload from Fetch Users:', payload)
    try {
      const response = await POSTCallHelper('users/filter', payload)
      context.commit('setLocumProfiles', response.data?.users?.data)
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async inviteUserToSession (context, payload) {
    try {
      const response = await POSTCallHelper(
        're/locums/sessions/invite',
        payload
      )
      return response;
      //context.commit('setLocumProfiles', response.data?.users?.data)
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async fetchUserInvites (context, payload) {
    try {
      const response = await POSTCallHelper(
        're/locums/sessions/fetch-user-invites',
        payload
      )
      context.commit('setUserInvites', response.data?.['session-invites'])
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async fetchRoles (context) {
    try {
      const response = await GETCallHelper('roles')
      var filteredArr = [];
      var roleData = [];
        if (response.data?.roles.length !== 0) {
          response.data?.roles.forEach((item) => {
            item.name = item.name[0].toUpperCase() + item.name.substring(1);
            const result = item.name.replaceAll("_", " ");
            roleData.push({ code: item.id, label: result });
          });
          filteredArr = roleData.reduce((acc, current) => {
            const x = acc.find(item => item.code === current.code);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
        }
        if(filteredArr.length > 0){
          filteredArr = filteredArr.filter(item => !['Admin', 'Recruiter', 'Manager', 'Hq', 'Super admin'].includes(item.label));
        }
    context.commit('setRoles', filteredArr)
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async fetchLocations (context) {
    try {
      const response = await GETCallHelper('practices')
      context.commit('setLocations', response.data?.practices)
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async fetchBillingSessions (context, payload = {}) {
    try {
      const response = await POSTCallHelper(
        'us/locum/sessions/billing',
        payload
      )
      context.commit('setBillingSessions', response.data?.['locum-invoices'])
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async fetchMonthlyFilterSessions (context, payload) {
    try {
      const response = await POSTCallHelper('us/locum/sessions', payload)
      context.commit(
        'setMonthlyFilterSessions',
        response.data?.['locum-sessions']?.data
      )
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async fetchMonthlyInvites (context, payload) {
    try {
      const response = await POSTCallHelper(
        'us/locum/sessions/session-invites',
        payload
      )
      context.commit('setMonthlyInvites', response.data?.['session-invites'])
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async sessonInviteAction (context, payload) {
    try {
      const response = await POSTCallHelper(
        'us/locum/sessions/invitation-action',
        payload
      )
      context.commit('setSessionInviteResponse', response)
    } catch (error) {
      handleError(error.response.data)
    }
  },
}
