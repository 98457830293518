import { POSTCallHelper, GETCallHelper, GETPDFCallHelper } from '../../../services'
import { handleError } from '../../../errorServices'

export default {
  async createPatientSurvey ({ commit }, payload) {
    try {
      const response = await POSTCallHelper('hq/patient-surveys/create', payload)
      commit('setPatientSurveys', response)
      return response.data['patient-survey'];
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSingleSurvey ({ commit }, payload) {
    try {
      const response = await POSTCallHelper('hq/patient-surveys/patient-survey', {
        patient_survey: payload
      })

      commit('setPatientSurvey', response.data['patient-survey']);
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchAllSurveys ({ commit }) {
    try {
      const response = await GETCallHelper('hq/patient-surveys')
      commit('setPatientSurveys', response.data['patient-surveys'])
      return response.data['patient-surveys'];
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchQuestions ({ commit }) {
    try {
      const response = await GETCallHelper('hq/patient-surveys/fetch-survey-questions')
      commit('setQuestionsAndAnswers', response.data['survey-questions'])
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSurveyFeedbacks({ commit }, data) {
    try {
      // Construct the URL with query parameters based on filters
      let url = `hq/patient-surveys/fetch-survey-feedbacks?page=${data?.page ? data?.page : data}`;
      if (data?.date) {
        url += `&date=${data?.date}`;
      }
      if (data?.feedbackType) {
        url += `&type=${data?.feedbackType}`;
      }
      if (data?.practice) {
        url += `&practice=${encodeURIComponent(data?.practice)}`;
      }
  
      const response = await GETCallHelper(url);
      commit('setSurveyFeedbacks', response.data['survey-feedbacks']);
      return response.data['survey-feedbacks'];
    } catch (e) {
      handleError(e.response.data);
    }
  },
  async loadPractices(context) {
    try {
      const response = await GETCallHelper("hq/practices");
      context.commit("setPractices", response.data.practices);
      return response.data.practices;
    } catch (e) {
      handleError(e.response.data);
    }
  },
  async fetchQuestionStats ({ commit }) {
    try {
      const response = await GETCallHelper('hq/patient-surveys/question-stats')
      commit('setQuestionStats', response.data['survey-questions'])
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchPracticeStats (_, payload) {
    try {
      const response = await GETCallHelper('hq/patient-surveys/practice-stats', { survey_question: payload.survey_question }, payload.page)
      return response.data['practices'];
    } catch (e) {
      handleError(e);
    }
  },

  async fetchOptionStats ({ commit }, payload ) {
    try {
      const response = await GETCallHelper('hq/patient-surveys/options-stats', payload);
      commit('setOptionStats', response.data['survey-questions']);
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSurveyCount ({ commit }) {
    try {
      const response = await GETCallHelper('hq/patient-surveys/submission-count');
      commit('setSurveyCount', response.data['submission-count'])
    } catch (e) {
      handleError(e.response.data)
    }
  },  

  async downloadSurveyPDF () {
    try {
      const response = await GETPDFCallHelper('hq/patient-surveys/generate-pdf');
      return response;
    } catch (e) {
      handleError(e.response.data)
    }
  },
}
