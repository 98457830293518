
const PatientSurveyDashboard = () =>
  import(
    '@/pages/MA/monitoringOfPracticeFunctions/PatientSurveys/Dashboard.vue'
  )

const AddPhysicalSurvey = () =>
  import(
    '@/pages/MA/monitoringOfPracticeFunctions/PatientSurveys/AddPhysicalSurvey.vue'
  )

const SurveyEntries = () =>
  import(
    '@/pages/MA/monitoringOfPracticeFunctions/PatientSurveys/SurveyEntries.vue'
  )

const SurveyPreview = () =>
  import(
    '@/pages/MA/monitoringOfPracticeFunctions/PatientSurveys/SurveyPreview.vue'
  )

  const CreateActionPlan = () =>
  import(
    '@/pages/MA/monitoringOfPracticeFunctions/PatientSurveys/ActionPlans/CreateActionPlan.vue'
  )
const actionPlanDetailPageMA = () => import("@/pages/MA/actionPlanDetailPageMA.vue");

const maPatientSurveyRoutes = [
    {
        path: '',
        name: 'ma-MOPFPatientSurveyDash',
        component: PatientSurveyDashboard
      },
      {
        path: 'MopfAddPhysicalSurvey',
        name: 'ma-mopfAddPhysicalSurvey',
        component: AddPhysicalSurvey
      },
      {
        path: 'ma-survey-entries/:id/survey/:isActive',
        name: 'ma-survey-entries',
        component: SurveyEntries
      },
      {
        path: 'ma-survey-preview',
        name: 'ma-survey-preview',
        component: SurveyPreview
      },  
      {
        path: "actionPlanDetailPageMA/:actionPlanId/:qof/surveyId/:surveyId",
        name: "ma-actionPlan-detail-ps",
        component: actionPlanDetailPageMA,
      },
      {
        path: 'MopfCreateActionPlan/:qof',
        name: 'ma-mopfCreateActionPlan-ps',
        component: CreateActionPlan
      },
      {
        path: 'MopfCreateActionPlan/question/:questionId',
        name: 'ma-mopfQuestionActionPlan-ps',
        component: CreateActionPlan
      },
      {
        path: 'MopfCreateActionPlan/indicator/:indicatorId/subIndicator/:subIndicatorId/qof/:qof',
        name: 'ma-subIndicator-ActionPlan',
        component: CreateActionPlan
      },
]

export default maPatientSurveyRoutes