const actionPlanDetailPageMA = () => import("@/pages/MA/actionPlanDetailPageMA.vue");

const QofDashboard = () =>
  import(
    '@/pages/MA/monitoringOfPracticeFunctions/QOF/QOFDashboard.vue'
  )

const ViewEditQofData = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/QOF/ViewEditQofData.vue'
  )

const UploadQofData = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/QOF/UploadQOFData.vue'
  )

const IndicatorsDetailPage = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/QOF/IndicatorsDetail.vue'
  )

const CreateActionPlan = () =>
  import(
    '@/pages/MA/monitoringOfPracticeFunctions/PatientSurveys/ActionPlans/CreateActionPlan.vue'
  )


const maQofRoutes = [
    { path: '',
    name: 'ma-QofDash',
  component: QofDashboard
},
{
  path: 'actionPlan/indicator/:indicatorId/subIndicator/:subIndicatorId/qof/:qof',
  name: 'ma-QOF-ActionPlan',
  component: CreateActionPlan
},
{
  path: 'ma-qof-view-edit',
  name: 'ma-qof-view-edit',
  component: ViewEditQofData
},
{
  path: 'monitoringOfPracticeFunctionsAddQofData/mode/:mode?/qof-entry/:id?',
  name: 'ma-add-qof-data',
  component: UploadQofData
},
{
  path: 'ma-indicator-details/:id',
  name: 'ma-indicator-details',
  component: IndicatorsDetailPage,
},
{
  path: 'actionPlan/indicator/:indicatorId/subIndicator/:subIndicatorId/qof/:qof',
  name: 'ma-QOF-ActionPlan',
  component: CreateActionPlan
},
{
  path: 'MopfCreateActionPlan/:qof',
  name: 'ma-mopfCreateActionPlan-qof',
  component: CreateActionPlan
},
{
  path: "actionPlanDetailPageMA/:actionPlanId/:qof/surveyId/:surveyId",
  name: "ma-actionPlan-detail-qof",
  component: actionPlanDetailPageMA,
},
]

export default maQofRoutes