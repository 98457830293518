/** @format */

import {
  DELETECallHelper,
  GETCallHelper,
  POSTCallHelper,
} from "../../../services";
import { handleError } from "../../../errorServices";

export default {
  async loadStaffGroup() {
    try {
      const response = await GETCallHelper(`/hq/complaints/staff-groups`);
      return response.data["complaint-staff-groups"];
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async loadSubjectArea() {
    try {
      const response = await GETCallHelper(`/hq/complaints/subjects`);
      return response.data["complaint-subjects"];
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async deleteComplaint(_, payload) {
    try {
      await DELETECallHelper(
        `/hq/complaints/delete?complaint=`,
        payload.complaintId
      );
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async loadPractices({ commit }) {
    try {
      const response = await GETCallHelper("hq/practices");
      commit("setPractices", response.data.practices);
      return response.data.practices;
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async createComplaint(_, payload) {
    try {
      await POSTCallHelper("/hq/complaints/create", payload);
    } catch (e) {
      handleError(e);
    }
  },

  async fetchAllComplaints({ commit }, payload) {
    try {
      let url = `/hq/complaints?page=${payload.page}&status=${payload.status}`;

      if (payload.filters) {
        if (payload.filters.subjectAreaFilters.length !== 0) {
          url += `&subject=${payload.filters.subjectAreaFilters}`;
        }
        if (payload.filters.staffGroupFilters.length !== 0) {
          url += `&staffGroup=${payload.filters.staffGroupFilters}`;
        }
        if (payload.filters.practiceFilters.length !== 0) {
          url += `&practice=${payload.filters.practiceFilters}`;
        }
        if (payload.filters.patientAgeGroupFilters.length !== 0) {
          url += `&agegroup=${payload.filters.patientAgeGroupFilters}`;
        }
        if (payload.filters.dateFilters.length !== 0) {
          url += `&startDate=${payload.filters.dateFilters[0].startDate}&endDate=${payload.filters.dateFilters[0].endDate}`;
        }
      }
      if (payload.search) {
        url += `&search=${payload.search}`;
      }
      const response = await GETCallHelper(url);
      commit("setAllComplaints", response.data["complaints"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchSingleComplaint({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/complaints/complaint?complaint=${payload.complaintId}`
      );

      commit("setComplaint", response.data["complaint"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async editComplaint({ commit }, payload) {
    try {
      const response = await POSTCallHelper(`/hq/complaints/update`, payload);
      commit("setComplaint", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async ackhnowledgeComplaint(_, payload) {
    try {
      await POSTCallHelper(`/hq/complaints/acknowledgement `, payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async updateComplaint({ commit }, payload) {
    try {
      const response = await POSTCallHelper(`/hq/complaints/updates `, payload);
      commit("refreshUpdates", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async askExtension({ commit }, payload) {
    try {
      const response = await POSTCallHelper(
        `/hq/complaints/extension `,
        payload
      );
      commit("grantExtension", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async submitOutcome(_, payload) {
    try {
      await POSTCallHelper(`/hq/complaints/outcome `, payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchComplaintsTypeStats({ commit }, payload) {
    try {
      let url = "/hq/complaints/complaints-count";
      if (payload?.filter) {
        url = `${url}?filter=${payload?.filter}`;
      }
      const response = await GETCallHelper(url);

      commit(
        "setComplaintTypeStats",
        payload?.filter ? response.data["data"] : response.data
      );
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchSubjectGroupStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/complaints/subject-group-complaints-count?filter=${payload.filter} `
      );

      commit("setSubjectGroupStats", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchStaffGroupStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/complaints/staff-group-complaints-count?filter=${payload.filter} `
      );

      commit("setStaffGroupStats", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchAgeGroupStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/complaints/age-group-complaints-count?filter=${payload.filter} `
      );

      commit("setAgeGroupStats", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchPatientRelationshipGroupStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/complaints/complainant-relationship-count?filter=${payload.filter} `
      );

      commit("setPatientRelationshipGroupStats", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchComplaintsAgainstPracticesStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/complaints/complaint-status-practice-count?filter=${payload.filter}`
      );

      commit("setComplaintsAgainstPracticesStats", response.data);
    } catch (e) {
      handleError(e.response.data);
    }
  },
};
