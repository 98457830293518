
const ComplimentsDashboard = () =>
import('@/pages/HQ/monitoringOfPracticeFunctions/Compliments/complimentsDashboard.vue')

const createViewCompliment = () =>
import('@/components/newUi/Compliments/CreateViewCompliments.vue')

const maComplimentsRoutes =[
    {
        path: "",
        name: "ma-monitoringOfPracticeFunctionComplimentDashboard",
        component: ComplimentsDashboard
      },
      {
        path: "monitoringOfPracticeFunctionsComplimentsDashboard/add-new-compliment/:view/:isManager",
        name: "ma-monitoringOfPracticeFunctionCompliment-newCompliment",
        component: createViewCompliment
      }
]
export default maComplimentsRoutes