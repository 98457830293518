import rules from '@/rules'
const locumSessions = () => import('@/pages/USER/locums/locumSessions.vue')
const locumBilling = () => import('@/pages/USER/locums/locumBilling.vue')
const locumInvites = () => import('@/pages/USER/locums/locumInvites.vue')
const userDetails = () => import('@/pages/USER/EmployeeProfile.vue')
const policyPreview = () => import('@/pages/CS/policyPreview.vue')

const routes = [
  {
    path: 'trainings',
    name: 'general-user-dashboard',
    component: () => import('@/components/layout/generalUser/index.vue')
  },
  {
    path: 'Locums',
    name: 'locums-dashboard',
    component: () => import('@/pages/USER/LocumDashboard.vue')
  },
  {
    path: 'userDetails',
    name: 'us-details',
    component: userDetails
  },
  {
    path: 'view-course/:id',
    name: 'user-view-course',
    component: () => import('@/pages/USER/ViewSingleCourse.vue')
  },
  {
    path: 'select-module-type',
    name: 'user-select-module-type',
    component: () => import('@/pages/USER/UserSelectModuleType.vue')
  },
  {
    path: 'user-create-new-course',
    name: 'user-create-new-course',
    component: () => import('@/pages/USER/CreateNewCourseUser.vue')
  },
  {
    path: 'practice-policies',
    name: 'user-practice-policies',
    component: () =>
      import('@/components/layout/generalUser/User/PracticePolicies.vue')
  },
  {
    path: 'locums',
    name: 'us-locums',
    component: () => import('@/pages/USER/locums/locums.vue'),
    beforeEnter: rules.isLocum
  },
  {
    path: 'locum-sessions',
    name: 'us-locum-sessions',
    component: locumSessions,
    beforeEnter: rules.isLocum
  },
  {
    path: 'locum-billing',
    name: 'us-locum-billing',
    component: locumBilling,
    beforeEnter: rules.isLocum
  },
  {
    path: 'locum-invites',
    name: 'us-locum-invites',
    component: locumInvites,
    beforeEnter: rules.isLocum
  },
  {
    path: 'policies/:policyId',
    name: 'us-policy',
    component: policyPreview,
    props: true,
    query: { policy: '' }
  }
]
export default routes
