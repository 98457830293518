import { handleError } from '../../errorServices'
import { POSTCallHelper, PATCHCallHelper } from '../../services.js'

export default {
  async fetchSingleInterview (context, payload) {
    try {
      const { data } = await POSTCallHelper('re/interviews/interview', payload)
      context.commit('setActiveInterview', data.interview)
      return data
      // console.log(response.data['interview-policies'].data);
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async saveAnswer (context, payload) {
    try {
      const data = await POSTCallHelper('re/interviews/answer', payload)
      context.commit('setQuestionsAnswer', payload)
      return data.response.messages
      // console.log(response.data['interview-policies'].data);
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async postSpecialQuestions (context, payload) {
    try {
      const { data } = await POSTCallHelper(
        `re/interviews/${payload.type}-questions/create`,
        payload
      )
      context.commit('setSepcialQuestions', payload)
      return data
      // console.log(response.data['interview-policies'].data);
    } catch (e) {
      console.log(e)
      handleError(e)
    }
  },
  async postMiscInfo (context, payload) {
    try {
      const { data } = await POSTCallHelper(
        `re/interviews/add-misc-info`,
        payload
      )
      return data
      // console.log(response.data['interview-policies'].data);
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async postScoreInfo (context, payload) {
    try {
      const { data } = await POSTCallHelper(
        `re/interviews/create-score`,
        payload
      )
      return data
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async completeInterview (context, payload) {
    try {
      const response = await PATCHCallHelper('re/interviews/update', payload)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async userEducation (context, payload) {
    try {
      const response = await POSTCallHelper('education', payload)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async acceptedCandidates (context, payload) {
    try {
      const response = await POSTCallHelper('users/filter', payload)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  }
}
