export default {
  getNewApplicationsHiringRequest (state) {
    return state.new_applications_hiring_request
  },
  getApprovedHiringRequest (state) {
    return state.approved_hiring_request
  },
  getEscalatedHiringRequest (state) {
    return state.escalated_hiring_request
  },
  getDeclinedHiringRequest (state) {
    return state.declined_hiring_request
  },
  getHiringRequest (state) {
    return state.hiring_request
  },
  getHiringRequestJobSpecification (state) {
    return state?.hiring_request?.job_specification
  },
  getSingleDepartment (state) {
    return state.singleDepartment
  },
  getUpcomingInterviews (state) {
    return state.upcomingInterviews
  },
  getAllInterviews (state) {
    return state.allInterviews
  },
  getAllOffers (state) {
    return state.allOffers
  },
  getVacanciesForFilter (state) {
    return state.vacancies_filtered
  },
  getCandidates (state) {
    return state.candidates
  },
  getPractices (state) {
    return state.practices
  }
}
