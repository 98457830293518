/** @format */

import maComplianceRoutes from "./compliance/maComplianceRoutes";
import maComplimentsRoutes from "./compliments/maComplimentsRoutes";
import maPatientSurveyRoutes from "./patientSurvey/maPatientSurveyRoutes";
import maQofRoutes from "./qof/maQofRoutes";
import maComplaintsRoutes from "./complaints/maComplaintsRoutes";
const MaMonitoringOfPractuceFunctionsDashboard = () =>
  import(
    "@/pages/MA/monitoringOfPracticeFunctions/monitoringOfPracticeFunctionsManagerDashboard.vue"
  );

const CreateActionPlan = () =>
  import(
    "@/pages/MA/monitoringOfPracticeFunctions/PatientSurveys/ActionPlans/CreateActionPlan.vue"
  );

// Model Day
const ModelDayDashboard = () =>
  import(
    "@/pages/MA/monitoringOfPracticeFunctions/ModelDay/ModelDayDashboard.vue"
  );

// Compliance calendar

//compliments

const mopfRoutes = [
  {
    path: "monitoringOfPracticeFunctionsDashboard",
    name: "ma-monitoringOfPracticeFunctionsDashboard",
    component: MaMonitoringOfPractuceFunctionsDashboard,
  },
  // Patient Survey Routes

  {
    path: "MopfPatientSurveyDash",
    children: maPatientSurveyRoutes,
  },

  // QOF Routes

  {
    path: "MopfQofDashboard",
    children: maQofRoutes,
  },

  // Action Plan Routes

  {
    path: "MopfCreateActionPlan/:qof",
    name: "ma-mopfCreateActionPlan",
    component: CreateActionPlan,
  },
  {
    path: "MopfCreateActionPlan/question/:questionId",
    name: "ma-mopfQuestionActionPlan",
    component: CreateActionPlan,
  },
  {
    path: "MopfCreateActionPlan/indicator/:indicatorId/subIndicator/:subIndicatorId/qof/:qof",
    name: "ma-subIndicator-ActionPlan",
    component: CreateActionPlan,
  },
  {
    path: "actionPlan/indicator/:indicatorId/subIndicator/:subIndicatorId/qof/:qof",
    name: "ma-QOF-ActionPlan",
    component: CreateActionPlan,
  },

  // Mdoel Day routes

  {
    path: "MopfModelDayDashboard",
    name: "ma-ModelDayDash",
    component: ModelDayDashboard,
  },

  // Compliance Routes

  {
    path: "MopfComplianceDashboard",
    children: maComplianceRoutes,
  },

  //Complaints Routes

  {
    path: "MopfComplaintsDashboard/:isManager",
    children: maComplaintsRoutes,
  },

  // Compliments
  {
    path: "monitoringOfPracticeFunctionsComplimentsDashboard/:isManager",
    children: maComplimentsRoutes,
  },
];

export default mopfRoutes;
