// import axios from "axios";
import { loginService, clearAuth } from './services'
import { handleError } from '../errorServices'
import { POSTCallHelper } from '../services.js'
export default {
  // Reset Password
  async resetPassword (_, payload) {
    try {
      await POSTCallHelper('auth/reset-password', payload)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Forgot Password
  async forgotPassword (_, payload) {
    try {
      await POSTCallHelper('auth/forgot-password', payload)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Logout
  async logout (context) {
    try {
      await POSTCallHelper('auth/logout')
      // Clear localStorage & Vuex
      clearAuth(context)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Login
  async login (context, payload) {
    context.commit('setLoading', true)
    try {
      const response = await POSTCallHelper('auth/login', payload)
      // Commit response to localStorage & Vuex
      await loginService(context, response?.data, response?.data.user.token)
    } catch (e) {
      console.log(e)
      context.commit('setIsLoggedIn', false)
      handleError(e.response.data)
    }
    context.commit('setLoading', false)
  },
  // Auto Login
  async tryLogin (context) {
    try {
      var formdata = new FormData()
      formdata.append('token', localStorage.getItem('token'))
      const response = await POSTCallHelper('auth/verify-token', formdata)
      // Commit response to localStorage & Vuex
      await loginService(context, response.data)
      return true
    } catch (e) {
      console.log(
        'THIS IS A CONSOLE LOG TO SEE WHAT HAPPENS AFTER TOKEN EXPIRES AND WE ARE STUCK ON WHITE SCREEN:, ',
        e
      )
      clearAuth(context)
      this.$router.push({ name: 'homepage' })
      return false
    }
  },
  async setTutorialAction (context, payload) {
    try {
      const response = await POSTCallHelper('us/me/update-profile', payload)
      context.dispatch('tryLogin')
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  }
}
