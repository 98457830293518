import { handleError } from '@/store/modules/errorServices'
import { POSTCallHelper } from '@/store/modules/services.js'
import { PATCHCallHelper } from '@/store/modules/services.js'
import {
  POSTAPPRAISALPOLICY,
  GETALLAPPRAISALPOLICIES,
  SCHEDULEAPPRAISALACTION,
  RESCHEDULEAPPRAISALACTION,
  GETALLSCHEDULEDAPPRAISALACTION,
  FETCHSINGLEAPPRAISALACTION,
  SAVEAPPRAISALANSWERACTION,
  ALLAPPRAISALPOLICIESMUTATION,
  SETLOADINGMUTATION,
  SETFETCHEDSCHEDULEDAPPRAISALSMUTATION,
  FETCHSINGLEAPPRAISMUTATION,
  SAVEAPPRAISALANSWERMUTATION,
  DELETEAPPRAISALPOLICY,
  SAVEOVERDUEAPPRAISALS,
  SAVEOVERDUEAPPRAISALSMUTATION,
  SAVEPENDINGAPPRAISALS,
  SAVEPENDINGAPPPRAISALSMUTATION,
  SAVEUPCOMINGPPRAISALS,
  SAVEUPCOMINGPPPRAISALSMUTATION,
  SAVERESCHEDULEDPPRAISALS,
  SAVERESCHEDULEDAPPRAISALSMUTATION
} from './constants'
handleError
export default {
  async [POSTAPPRAISALPOLICY] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper(
        'ma/appraisals/policies/create',
        payload
      )

      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },

  async [GETALLAPPRAISALPOLICIES] (context, payload = {}) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper(
        `${
          payload.fetchWithRole
            ? 'ma/appraisals/policies/role'
            : 'ma/appraisals/policies'
        }`,
        payload.fetchWithRole ? { role: payload.role } : payload
      )
      context.commit(
        ALLAPPRAISALPOLICIESMUTATION,
        payload.fetchWithRole
          ? response.data['appraisal-policy']
          : response.data['appraisal-policies'].data
      )
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      console.log('error inside create', e)
      handleError(e)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [SCHEDULEAPPRAISALACTION] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper('ma/appraisals/create', payload)
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [SAVEUPCOMINGPPRAISALS] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper('ma/appraisals', payload)
      context.commit(SETLOADINGMUTATION, false)
      context.commit(SAVEUPCOMINGPPPRAISALSMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [SAVERESCHEDULEDPPRAISALS] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper('ma/appraisals/reschedules', payload)
      context.commit(SETLOADINGMUTATION, false)
      context.commit(SAVERESCHEDULEDAPPRAISALSMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [RESCHEDULEAPPRAISALACTION] (context, { newObj, oldObj }) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response2 = await POSTCallHelper('ma/appraisals/create', newObj)
      const response1 = await PATCHCallHelper('ma/appraisals/update', {
        ...oldObj,
        appraisal_reference: response2.data.appraisal.id
      })
      context.commit(SETLOADINGMUTATION, false)
      return { response1, response2 }
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [GETALLSCHEDULEDAPPRAISALACTION] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper(
        'ma/appraisals/all-appraisals',
        payload
      )
      context.commit(
        SETFETCHEDSCHEDULEDAPPRAISALSMUTATION,
        response.data.appraisals.data
      )
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [SAVEOVERDUEAPPRAISALS] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper('ma/appraisals/overdue', payload)
      context.commit(
        SAVEOVERDUEAPPRAISALSMUTATION,
        response.data['overdue-appraisals'].data
      )
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [SAVEPENDINGAPPRAISALS] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper(
        'ma/appraisals/all-appraisals',
        payload
      )
      context.commit(
        SAVEPENDINGAPPPRAISALSMUTATION,
        response.data.appraisals.data
      )
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [FETCHSINGLEAPPRAISALACTION] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper('ma/appraisals/appraisal', payload)
      context.commit(FETCHSINGLEAPPRAISMUTATION, response.data['appraisal'])
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [SAVEAPPRAISALANSWERACTION] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper('ma/appraisals/answer', payload)
      context.commit(SAVEAPPRAISALANSWERMUTATION, response)
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [DELETEAPPRAISALPOLICY] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper(
        'ma/appraisals/policies/delete',
        payload
      )
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async concludeAppraisal (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await PATCHCallHelper(
        'ma/appraisals/conclude-appraisal',
        payload
      )
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async submitAppraisalGoals (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper(
        'ma/appraisals/user-objectives/create',
        payload
      )
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async deleteAppraisalGoal (context, payload) {
    try {
      const response = await POSTCallHelper(
        'ma/appraisals/user-objectives/delete',
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async editAppraisalGoal (context, payload) {
    try {
      const response = await PATCHCallHelper(
        'ma/appraisals/user-objectives/update',
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  }
}
