export const validators = {
  salaryValidator: (data) => {
    console.log(data)
    const {
      current_salary,
      expected_salary,
      availability,
      available_time,
      job_type,
      dbs,
      dismissals,
    } = data;
    const allVars = {
      current_salary,
      expected_salary,
      availability,
      available_time,
      job_type,
      dbs,
      dismissals,
    };
    const validate = Object.keys(allVars).map((item) =>
      !allVars[item] ? item : false
    );
    return validate.filter(function (v) {
      return !!v;
    });
  },
  notcePeriodValidtor: (data) => {
    console.log(data)
    const {
      given_notice,
      notice_start,
      notice_duration,
      interviewing_elsewhere,
    } = data;
    const allVars = {
      given_notice,
      notice_start,
      notice_duration,
      interviewing_elsewhere,
    };
    const validate = Object.keys(allVars).map((item) =>
      !allVars[item] ? item : false
    );
    return validate.filter(function (v) {
      return !!v;
    });
  },
};
