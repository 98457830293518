/** @format */

export default {
  setAllCompliances(state, payload) {
    state.AllCompliances = payload;
  },

  setSingleCompliance(state, payload) {
    state.singleCompliance = payload;
  },

  updateStatus(state, payload) {
    state.singleCompliance.practices[0].status = payload;
  },

  updateFileSubmission(state, payload) {
    state.singleCompliance.submitted_compliance_item_files = [
      payload,
      ...state.singleCompliance.submitted_compliance_item_files,
    ];
  },
};
