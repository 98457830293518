export default {
  getQuestionStats(state) {
    return state.questionStats;
  },
  getActionPlans(state) {
    return state.actionPlans;
  },
  getFeedbacks(state) {
    return state.feedbacks;
  },
  getSurveyQuestions(state) {
    return state.questions;
  },
  getActiveSurvey(state) {
    return state.activeSurvey;
  },
  getOptionStats(state) {
    return state.optionStats;
  },
  getPracticeSurveyCount(state) {
    return state.practiceSurveyCount;
  },
  getPatientSurveys (state) {
    return state.patientSurveys;
  },
  getActiveSurveys(state) {
    return state.patientSurveys.filter((s) => s.is_active == 1)
  },
  getArchivedSurveys(state) {
    return state.patientSurveys.filter((s) => s.is_active !== 1)
  },
  getSingleSurvey (state) {
    return state.patientSurvey
  },
}