import { handleError } from '@/store/modules/errorServices'
import { POSTCallHelper, GETCallHelper } from '@/store/modules/services.js'
import {
  FETCH_ALL_COURSES_ACTION,
  STORE_A_COURSE_ACTION,
  STORE_A_COURSE_MODULE_ACTION,
  SET_ALL_COURSES_MUTATION,
  SET_ALL_COURSES_FAILED_MUTATION,
  SET_LOADING_MUTATION,
  STORE_A_COURSE_MODULE_MUTATION,
  SET_CURRENT_COURSE_MUTATION,
  STORE_A_MODULE_LESSON_ACTION,
  ASSIGN_A_USER_COURSES,
  FETCH_ALL_EMPLOYEES_WITH_COURSES,
  STORE_ALL_EMPLOYEES_WITH_COURSES,
  FETCH_A_SINGLE_COURSE_ACTION,
  SET_A_SINGLE_COURSE_MUTATION,
  FETCH_SINGLE_USER,
  SET_SINGLE_USER,
  ENROLL_A_USER_TO_COURSE,
  MANIPULATE_USERS_ON_COURSE,
  FETCH_ALL_USERS_TO_ENROLL_UNROLL,
  SET_ALL_USERS_TO_ENROLL_AND_UNROLL,
  UPDATE_DATES_FOR_ENROLLED_USERS
} from './constants'

export default {
  async [FETCH_ALL_COURSES_ACTION] ({ commit }, payload) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const url = `ma/training-courses${payload ? '?page=' + payload : ''}`
      const response = await POSTCallHelper(url)
      commit(SET_ALL_COURSES_MUTATION, response.data['training-courses'])
    } catch (e) {
      commit(SET_ALL_COURSES_FAILED_MUTATION)
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  },

  async [FETCH_A_SINGLE_COURSE_ACTION] ({ commit }, payload) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const url = `ma/training-courses/training-course`
      const response = await POSTCallHelper(url, payload)
      commit(SET_A_SINGLE_COURSE_MUTATION, response.data['training-course'])
    } catch (e) {
      commit(SET_ALL_COURSES_FAILED_MUTATION)
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  },

  async [STORE_A_COURSE_ACTION] ({ commit }, payload) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const response = await POSTCallHelper(
        'ma/training-courses/create',
        payload
      )
      commit(SET_CURRENT_COURSE_MUTATION, response.data['training-course'])
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  },
  async [STORE_A_COURSE_MODULE_ACTION] ({ commit }, payload) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const response = await POSTCallHelper(
        'ma/training-courses/modules/create',
        payload.data
      )
      commit(STORE_A_COURSE_MODULE_MUTATION, {
        data: response.data['course-module'],
        index: payload.index
      })
      return response
    } catch (e) {
      console.log(e)
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  },
  async [STORE_A_MODULE_LESSON_ACTION] (
    { commit },
    { data, course, module, index }
  ) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const response = await POSTCallHelper(
        'ma/training-courses/modules/lessons/create',
        data
      )
      commit(STORE_A_COURSE_MODULE_MUTATION, {
        data: response.data['lesson'],
        course,
        module,
        index
      })
      return response
    } catch (e) {
      console.log(e)
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  },
  async [ASSIGN_A_USER_COURSES] ({ commit }, payload) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const response = await POSTCallHelper(
        'ma/training-courses/enroll-user',
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  },
  async [FETCH_ALL_EMPLOYEES_WITH_COURSES] ({ commit }, payload = null) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const response = await GETCallHelper(
        `ma/employees${payload ? '?page=' + payload : ''}`
      )
      commit(STORE_ALL_EMPLOYEES_WITH_COURSES, response.data.employees)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  },
  async [FETCH_SINGLE_USER] ({ commit }, payload) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const response = await POSTCallHelper(`users/user`, payload)
      commit(SET_SINGLE_USER, response.data.user)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  },
  async [ENROLL_A_USER_TO_COURSE] ({ commit }, payload) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const response = await POSTCallHelper(
        `ma/training-courses/enroll-user`,
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  },
  async [MANIPULATE_USERS_ON_COURSE] ({ commit }, payload) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const response = await POSTCallHelper(payload.url, payload.data)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  },
  async [UPDATE_DATES_FOR_ENROLLED_USERS] ({ commit }, payload) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const response = await POSTCallHelper(
        `ma/training-courses/update-dates`,
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  },
  async [FETCH_ALL_USERS_TO_ENROLL_UNROLL] ({ commit }, payload) {
    commit(SET_LOADING_MUTATION, true)
    try {
      const response = await GETCallHelper(
        `ma/employees${payload ? '?page=' + payload : ''}`
      )
      commit(SET_ALL_USERS_TO_ENROLL_AND_UNROLL, response.data.employees)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    commit(SET_LOADING_MUTATION, false)
  }
}
