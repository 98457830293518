const ComplianceDashboardMA = () =>
  import(
    '@/pages/MA/monitoringOfPracticeFunctions/Compliance/ComplianceDashboardMA.vue'
  )

const SubmitCompliance = () =>
  import(
    '@/pages/MA/monitoringOfPracticeFunctions/Compliance/SubmitCompliance.vue'
  )

const maComplianceRoutes = [
    {
        path: '',
        name: 'ma-ComplianceDashboard',
        component: ComplianceDashboardMA
      },
      {
          path: 'MopfSubmitCompliance/compliance/:complianceId',
          name: 'ma-SubmitCompliance',
          component: SubmitCompliance
      }
] 

export default maComplianceRoutes