import { handleError } from '../../errorServices'
import {
  POSTCallHelper,
  GETCallHelper,
  PATCHCallHelper
} from '../../services.js'

export default {
  async createSession (context, payload) {
    try {
      await POSTCallHelper('/re/locums/sessions/create', payload)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSessions (context, payload = {}) {
    try {
      const response = await POSTCallHelper(
        `re/locums/sessions?page=${payload.page}`,
        payload
      )
      const sessions = response?.data?.['locum-sessions']
      context.commit('setSessions', sessions)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchMonthlySessions (context, payload = {}) {
    try {
      const response = await POSTCallHelper('re/locums/sessions/month', payload)
      //console.log(response.data)
      const sessions = response?.data?.['sessions-by-month']
      context.commit('setMonthlySessions', sessions)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchTodaysSessions (context, payload = {}) {
    try {
      const response = await POSTCallHelper('re/locums/sessions/day', payload)
      //console.log(response.data)
      const sessions = response?.data?.['sessions-by-day']
      context.commit('setTodaysSessions', sessions)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchLocumProfiles (context, payload) {
    try {
      const response = await POSTCallHelper(
        `users/filter?page=${payload.page}`,
        payload
      )
      context.commit('setLocumProfiles', response)
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async inviteUserToSession (context, payload) {
    try {
      await POSTCallHelper(
        're/locums/sessions/invite',
        payload
      )
      // context.commit('setLocumProfiles', response.data?.users?.data)
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async fetchUserInvites (context, payload) {
    try {
      const response = await POSTCallHelper(
        're/locums/sessions/fetch-user-invites',
        payload
      )
      context.commit('setUserInvites', response.data?.['session-invites'])
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async fetchRoles (context) {
    try {
      const response = await GETCallHelper('roles')
      var filteredArr = [];
      var roleData = [];
        if (response.data?.roles.length !== 0) {
          response.data?.roles.forEach((item) => {
            item.name = item.name[0].toUpperCase() + item.name.substring(1);
            const result = item.name.replaceAll("_", " ");
            roleData.push({ code: item.id, label: result });
          });
          filteredArr = roleData.reduce((acc, current) => {
            const x = acc.find(item => item.code === current.code);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
        }
        if(filteredArr.length > 0){
          filteredArr = filteredArr.filter(item => !['Admin', 'Recruiter', 'Manager', 'Hq', 'Super admin'].includes(item.label));
        }
      context.commit('setRoles', filteredArr)
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async fetchLocations (context) {
    try {
      const response = await GETCallHelper('practices')
      context.commit('setLocations', response.data.practices)
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async fetchBillingSessions (context, payload = {}) {
    try {
      const response = await POSTCallHelper(
        're/locums/sessions/billing',
        payload
      )
      context.commit('setBillingSessions', response.data?.['locum-invoices'])
    } catch (error) {
      handleError(error.response.data)
    }
  },

  async fetchMonthlyFilterSessions (context, payload = {}) {
    try {
      const response = await POSTCallHelper('re/locums/sessions/month', payload)
      //console.log(response.data)
      const sessions = response?.data?.['sessions-by-month']
      context.commit('setMonthlyFilterSessions', sessions)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async addLocumNotes (context, payload) {
    try {
      await POSTCallHelper('/re/locums/notes/create', payload)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async removeUserAsLocum (context, payload) {
    try {
      await PATCHCallHelper('/re/locums/user-locum-status', payload)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async addLocumToBlacklist (context, payload) {
    try {
      await POSTCallHelper('/re/locums/add-to-blacklist', payload)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async removeFromBlacklist (context, payload) {
    try {
      await POSTCallHelper('/re/locums/remove-from-blacklist', payload)
    } catch (e) {
      handleError(e.response.data)
    }
  }
}
