// Actions
export const FETCH_ALL_COURSES_ACTION = 'FETCH_ALL_COURSES_ACTION'
export const STORE_A_COURSE_ACTION = 'STORE_A_COURSE_ACTION'
export const STORE_A_COURSE_MODULE_ACTION = 'STORE_A_COURSE_MODULE_ACTION'
export const STORE_A_MODULE_LESSON_ACTION = 'STORE_A_MODULE_LESSON_ACTION'
export const ASSIGN_A_USER_COURSES = 'ASSIGN_A_USER_COURSES'
export const FETCH_ALL_EMPLOYEES_WITH_COURSES =
  'FETCH_ALL_EMPLOYEES_WITH_COURSES'
export const FETCH_A_SINGLE_COURSE_ACTION = 'FETCH_A_SINGLE_COURSE_ACTION'
export const FETCH_SINGLE_USER = 'FETCH_SINGLE_USER'
export const ENROLL_A_USER_TO_COURSE = 'ENROLL_A_USER_TO_COURSE'
export const MANIPULATE_USERS_ON_COURSE = 'MANIPULATE_USERS_ON_COURSE'
export const FETCH_ALL_USERS_TO_ENROLL_UNROLL =
  'FETCH_ALL_USERS_TO_ENROLL_UNROLL'
export const UPDATE_DATES_FOR_ENROLLED_USERS =
  'UPDATE_DATES_FOR_ENROLLED_USERS'

// mutations
export const SET_LOADING_MUTATION = 'SET_LOADING_MUTATION'
export const SET_ALL_COURSES_MUTATION = 'SET_ALL_COURSES_MUTATION'
export const SET_ALL_COURSES_FAILED_MUTATION = 'SET_ALL_COURSES_FAILED_MUTATION'
export const STORE_A_COURSE_MODULE_MUTATION = 'STORE_A_COURSE_MODULE_MUTATION'
export const SET_CURRENT_COURSE_MUTATION = 'SET_CURRENT_COURSE_MUTATION'
export const SET_SINGLE_COURSE_MUTATION = 'SET_SINGLE_COURSE_MUTATION'
export const SET_SINGLE_MODULE_LESSON_MUTATION =
  'SET_SINGLE_MODULE_LESSON_MUTATION'
export const STORE_ALL_EMPLOYEES_WITH_COURSES =
  'STORE_ALL_EMPLOYEES_WITH_COURSES'
export const SET_A_SINGLE_COURSE_MUTATION = 'SET_A_SINGLE_COURSE_MUTATION'
export const SET_SINGLE_USER = 'SET_SINGLE_USER'
export const SET_ALL_USERS_TO_ENROLL_AND_UNROLL =
  'SET_ALL_USERS_TO_ENROLL_AND_UNROLL'

// getters
export const GET_LOADING_GETTER = 'GET_LOADING_GETTER'
export const GET_COURSES_GETTER = 'GET_COURSES_GETTER'
export const ADD_LESSON_BUTTON_ENABLED_GETTER =
  'ADD_LESSON_BUTTON_ENABLED_GETTER'
export const GET_CURRENT_COURSE_GETTER = 'GET_CURRENT_COURSE_GETTER'
export const GET_COURSES_TOTAL_GETTER = 'GET_COURSES_TOTAL_GETTER'
export const GET_ALL_EMPLOYEES_WITH_COURSES_GETTER =
  'GET_ALL_EMPLOYEES_WITH_COURSES_GETTER'
export const GET_A_SINGLE_COURSE_GETTER = 'GET_A_SINGLE_COURSE_GETTER'
export const SET_SINGLE_USER_GETTER = 'SET_SINGLE_USER_GETTER'
export const GET_ALL_USERS_TO_ENROLL_AND_UNROLL_GETTER =
  'GET_ALL_USERS_TO_ENROLL_AND_UNROLL_GETTER'
