import { createToaster } from '@meforma/vue-toaster'
import Router from '../../router/router.js';

const toaster = createToaster({
  /* options */
  position: 'top-right'
})

// Pass endpoint response to trigger an error modal

export function handleError (response) {
  let error = null
  if (response) {
    if (response.message === 'Expired or Invalid token') {
      localStorage.clear();
      Router.go('/');
    }
    toaster.show(response.message)
    error = new Error(response.message)
  } else {
    toaster.show('Something went wrong')
    error = new Error('Something went wrong')
  }
  throw error
}
