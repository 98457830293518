//Main
const recruitment = () => import("@/pages/HQ/humanResources/recruitment.vue");
const HqStaffRequestForm = () =>
  import("@/pages/HQ/humanResources/HQ/HqStaffRequestForm.vue");
// const jobPersonSpec = () => import("@/pages/jobPersonSpec.vue");
const candidateOffers = () =>
  import("@/pages/HQ/humanResources/candidateOffers.vue");
const candidateOfferAmmend = () =>
  import("@/pages/HQ/humanResources/candidateOfferAmmend.vue");
const interviews = () => import("@/pages/HQ/humanResources/interviews.vue");
const openedInterview = () =>
  import("@/pages/HQ/humanResources/openedInterview.vue");
const employeeDetails = () => import("@/pages/employeeDetails.vue");

const routes = [
  //HQ recruitment screen
  {
    path: "recruitment",
    name: "hq-recruitment",
    component: recruitment,
    query: { success: "", error: "" },
  },
  // //Manager New Staff screen
  {
    path: "recruitment/:staffRequestForm",
    name: "hq-staff-request-form",
    component: HqStaffRequestForm,
  },
  //HQ Job and person Spec
  // {
  //   path: "jobPersonSpecification",
  //   name: "hq-job-person-specification",
  //   component: jobPersonSpec,
  // },
  //HQ recruitment screen
  {
    path: "candidateOffers",
    name: "hq-candidate-offers",
    component: candidateOffers,
    query: { success: "", error: "" },
  },
  {
    path: "candidateOffers/:offerId",
    name: "hq-candidate-offer-ammend",
    component: candidateOfferAmmend,
  },
  {
    path: "interviews",
    name: "hq-interviews",
    component: interviews,
    query: { success: "", error: "" },
  },
  {
    path: "interviews/:interviewId",
    name: "hq-opened-interview",
    component: openedInterview,
  },
  {
    path: "employeeDetails/:userId",
    name: "hq-employee-details",
    component: employeeDetails,
  },
];

export default routes;
