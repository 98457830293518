import axios from 'axios'
import Swal from "sweetalert2";
import icon from "@/assets/icons/error.svg";
export default (router) => {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const is401 = error.response && error.response.status === 401
      if (is401 && error.response.data.message.toLowerCase().includes("token")) {
        Swal.fire({
            width: "35%",
            iconHtml: `<img src="${icon}" />`,
            title: 'Your Session Has Been Expired!',
            showConfirmButton: false,
            showClass: {
                content: "custom-text",
                icon: "icon-border",
                popup: "border-radius",
            },
            background: `rgba(225, 249, 240, 1)`,
            didClose: () => {
                localStorage.clear()
                router.go(0);
            }
      })
        
    }   
      return Promise.reject(error)
    }
  )
}
