/** @format */

export default {
  setActivities(state, payload) {
    state.activities = payload;
  },

  setPractices(state, payload) {
    state.practices = payload;
  },

  addActivities(state, payload) {
    payload.map((item) => {
      state.activities.data.splice(0, 0, item.question);
    });
  },

  setSingleActivity(state, payload) {
    state.activity = payload;
  },

  setInstanceRequests(state, payload) {
    state.instanceRequests = payload;
  },

  setInstanceSubmissions(state, payload) {
    state.instanceSubmissions = payload;
  },
  updateInstanceRequestStatus(state, payload) {
    state.instanceRequests.data[0].instance_requests[payload.instanceId] =
      payload.response;
  },

  setInstanceCount(state, payload) {
    state.instanceCount = payload;
  },
  setSingleInstance(state, payload) {
    state.singleInstance = payload;
  },
  setThresholds(state, payload) {
    state.thresholds = payload;
  },
  setQuestions(state, payload) {
    state.questions = payload;
  },

  setDashInstanceCount(state, payload) {
    state.dashInstanceCount = payload;
  },
};
