export default {
  setQuestionStats(state, payload) {
    state.questionStats = payload
  },
  setActionPlans(state, payload) {
    state.actionPlans = payload;
  },
  setSurveyFeedbacks(state, payload) {
    state.feedbacks = payload;
  },
  setSurveyQuestions(state, payload) {
    state.questions = payload;
  },
  setActiveSurvey(state, payload) {
    state.activeSurvey = payload;
  },
  setOptionStats(state, payload) {
    state.optionStats = payload;
  },
  setPracticeSurveyCount(state, payload) {
    state.practiceSurveyCount = payload;
  },
  setPatientSurveys (state, payload) {
    state.patientSurveys = payload
  },
  setPatientSurvey (state, payload) {
    state.patientSurvey = payload
  },
}