
export default {
    setPractices(state, payload) {
        state.practices = payload;
      },
      setAllCompliments(state,payload){
        state.allCompliments = payload
      },
      setCompliment(state, payload){
        state.compliment = payload
      }
}