export default {
  setAllQuestions (state, payload) {
    state.questions = payload
  },
  setAllActionPlans (state, payload) {
    state.actionPlans = payload
  },
  setSingleActionPlan (state, payload) {
    state.singleActionPlan = payload
  }
}
