import { handleError } from '../errorServices'
import { GETCallHelper, POSTCallHelper, PATCHCallHelper } from '../services.js'

export default {
  // FETCH Profile Data
  async loadProfile (context) {
    try {
      const response = await POSTCallHelper('users/me')
      context.commit('setProfile', response.data.user)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Get Work Patterns
  async fetchWorkPatterns (context) {
    try {
      const response = await GETCallHelper('work-patterns')
      context.commit('setWorkPatterns', response.data.work_patterns)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Work Timings Data
  async loadWorkTimings (context, payload) {
    try {
      const response = await POSTCallHelper('work-timings', payload)
      context.commit('setWorkTimings', response.data.work_timings)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Miscellaneous Information Data
  async fetchMiscInfo (context, payload) {
    try {
      const response = await POSTCallHelper('misc-info', payload)
      context.commit('setMiscInfo', response.data['misc-info'])
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Employment Checks Data
  async fetchEmploymentChecks (context, payload) {
    try {
      const response = await POSTCallHelper('employment-checks', payload)
      context.commit('setEmploymentChecks', response.data['employment-check'])
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Employment Policies Data
  async fetchEmploymentPolicies (context, payload) {
    try {
      const response = await POSTCallHelper('employment-policies', payload)
      context.commit(
        'setEmploymentPolicies',
        response.data['employment-policies']
      )
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Experiences Information Data
  async fetchExperiencesInformation (context, payload) {
    try {
      const response = await POSTCallHelper('employment-histories', payload)
      context.commit(
        'setExperiencesInformation',
        response.data['employment-histories']
      )
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH References Data
  async fetchReferences (context, payload) {
    try {
      const response = await POSTCallHelper('references', payload)
      context.commit('setReferences', response.data.references.data)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Education Information
  async fetchEducationInformation (context, payload) {
    try {
      const response = await POSTCallHelper('education', payload)
      context.commit('setEducation', response.data.education.data)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE Contract Summary
  async updateContractSummary (context, payload) {
    try {
      await POSTCallHelper('contract-summaries/update', payload)
      await context.dispatch('loadProfile')
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE Position Information
  async updatePositionInformation (context, payload) {
    try {
      await POSTCallHelper('position-summaries/update', payload)
      await context.dispatch('loadProfile')
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE User Information
  async userUpdate (context, payload) {
    try {
      const response = await PATCHCallHelper('profiles/update', payload)
      console.log('response: ', response.data)
      // await context.dispatch("loadProfile");
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE Misc Information
  async updateMiscInformation (context, payload) {
    try {
      console.log('-----------------------')
      for (var pair of payload.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      const response = await POSTCallHelper('misc-info/update', payload)
      console.log('response: ', response.data)
      console.log('response NEW: ', response.data['misc-info'].user_id)
      await context.dispatch('fetchMiscInfo', {
        user: response.data['misc-info'].user_id
      })
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE Employment Checks
  async updateEmploymentChecks (context, payload) {
    try {
      const response = await POSTCallHelper('employment-checks/update', payload)
      console.log('response: ', response.data)
      await context.dispatch('loadProfile')
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE Employment Policies Signature
  async signEmploymentPolicy (context, payload) {
    try {
      const response = await POSTCallHelper(
        'employment-policies/update',
        payload
      )
      console.log('response: ', response.data)
      await context.dispatch('loadProfile')
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // CREATE Experience Information
  async createExperienceInformation (context, payload) {
    try {
      const response = await POSTCallHelper(
        'employment-histories/create',
        payload
      )
      console.log('response: ', response.data)
      // This should only be used when working inside the PROFILE...
      // await context.dispatch("fetchExperiencesInformation", {
      //   user: context.getters.getProfile.id,
      // });
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE Experience Information
  async updateExperienceInformation (context, payload) {
    try {
      const response = await POSTCallHelper(
        'employment-histories/update',
        payload
      )
      console.log('response: ', response.data)
      // This should only be used when working inside the PROFILE...
      // await context.dispatch("fetchExperiencesInformation", {
      //   user: context.getters.getProfile.id,
      // });
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // DELETE Experience Information
  async deleteExperience (context, payload) {
    try {
      const response = await POSTCallHelper(
        'employment-histories/delete',
        payload
      )
      console.log('response: ', response.data)
      // This should only be used when working inside the PROFILE...
      // await context.dispatch("fetchExperiencesInformation", {
      //   user: context.getters.getProfile.id,
      // });
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // CREATE References Information
  async createReferences (context, payload) {
    try {
      const response = await POSTCallHelper('references/create', payload)
      console.log('response: ', response.data)
      // This should only be used when working inside the PROFILE...
      // await context.dispatch("fetchReferences", {
      //   user: context.getters.getProfile.id,
      // });
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE References Information
  async updateReferences (context, payload) {
    try {
      const response = await POSTCallHelper('references/update', payload)
      console.log('response: ', response.data)
      // This should only be used when working inside the PROFILE...
      // await context.dispatch("fetchReferences", {
      //   user: context.getters.getProfile.id,
      // });
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // DELETE References Information
  async deleteReference (context, payload) {
    try {
      const response = await POSTCallHelper('references/delete', payload)
      console.log('response: ', response.data)
      // This should only be used when working inside the PROFILE...
      // await context.dispatch("fetchReferences", {
      //   user: context.getters.getProfile.id,
      // });
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // CREATE Education Information
  async createEducation (context, payload) {
    try {
      console.log('Paylioad: ', payload)
      const response = await POSTCallHelper('education/create', payload)
      console.log('response: ', response.data)
      // This should only be used when working inside the PROFILE...
      // await context.dispatch("fetchEducationInformation", {
      //   user: context.getters.getProfile.id,
      // });
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE Education Information
  async updateEducation (context, payload) {
    try {
      const response = await POSTCallHelper('education/update', payload)
      console.log('response: ', response.data)
      // This should only be used when working inside the PROFILE...
      // await context.dispatch("fetchEducationInformation", {
      //   user: context.getters.getProfile.id,
      // });
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // DELETE Education Information
  async deleteEducation (context, payload) {
    try {
      const response = await POSTCallHelper('education/delete', payload)
      console.log('response: ', response.data)
      // This should only be used when working inside the PROFILE...
      // await context.dispatch("fetchEducationInformation", {
      //   user: context.getters.getProfile.id,
      // });
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Emergency Contacts Data
  async fetchEmergencyContacts (context, payload) {
    try {
      const response = await POSTCallHelper('emergency-contacts', payload)
      context.commit(
        'setEmergencyContactInfo',
        response.data['emergency-contacts']
      )
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // CREATE Emergency Contact Information
  async createEmergencyContact (context, payload) {
    try {
      console.log('Paylioad: ', payload)
      const response = await POSTCallHelper(
        'emergency-contacts/create',
        payload
      )
      console.log('response: ', response.data)
      await context.dispatch('fetchEmergencyContacts', {
        user: context.getters.getProfile.id
      })
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE Education Information
  async updateEmergencyContact (context, payload) {
    try {
      const response = await POSTCallHelper(
        'emergency-contacts/update',
        payload
      )
      console.log('response: ', response.data)
      await context.dispatch('fetchEmergencyContacts', {
        user: context.getters.getProfile.id
      })
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // DELETE Emergency Contact
  async deleteEmergencyContact (context, payload) {
    try {
      const response = await POSTCallHelper(
        'emergency-contacts/delete',
        payload
      )
      console.log('response: ', response.data)
      await context.dispatch('fetchEmergencyContacts', {
        user: context.getters.getProfile.id
      })
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Termination Data
  async fetchTermination (context, payload) {
    try {
      const response = await POSTCallHelper('terminations', payload)
      context.commit('setTermination', response.data['termination'])
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE Termination Information
  async updateTermination (context, payload) {
    try {
      const response = await POSTCallHelper('terminations/update', payload)
      console.log('response: ', response.data)
      await context.dispatch('fetchTermination', {
        user: context.getters.getProfile.id
      })
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Registrations Data
  async fetchRegistrations (context, payload) {
    try {
      const response = await POSTCallHelper('legals', payload)
      context.commit('setRegistrations', response.data['legal'])
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // UPDATE Registrations Data
  async updateRegistration (context, payload) {
    try {
      console.log('-----------------------')
      for (var pair of payload.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      const response = await PATCHCallHelper('legals/update', payload)
      console.log('response: ', response.data)
      await context.dispatch('fetchRegistrations', {
        user: response.data.legal.user_id
      })
    } catch (e) {
      handleError(e.response.data)
    }
  }
}
