import { POSTCallHelper, GETCallHelper, PUTCallHelper } from '../../../services'
import { handleError } from '../../../errorServices'

export default {
  async createQofEntry (_, payload) {
    try {
      await POSTCallHelper('hq/qof/entries/create', payload)
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async updateQofEntry (_, payload) {
    try {
      await PUTCallHelper('hq/qof/entries/update', payload)
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async createIndicator (_, payload) {
    try {
      const response = await POSTCallHelper('hq/qof/indicators/create', payload)
      return response.data
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchIndicators ({ commit }) {
    try {
      const response = await GETCallHelper('hq/qof/indicators')
      commit('setIndicators', response.data['qof-indicators'])
      return response.data['qof-indicators']
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchQofEntries ({ commit }, payload) {
    try {
      const response = await GETCallHelper('hq/qof/entries',{ date: payload?.date, page: payload?.page})
      commit('setQofEntries', response.data['qof-entries']);
      return response.data['qof-entries']
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSingleQofEntry (_, id) {
    try {
      const response = await GETCallHelper(`hq/qof/entries/qof-entry?qof_entry=${id}`);
      return response.data['qof-entry']
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchIndicatorStats (_, payload) {
    try {
      const response = await GETCallHelper('/hq/qof/indicators/with-stats',{ order_by: payload?.order_by, name: payload?.name, practice: payload?.practice, page: payload?.page})
      return response.data.indicators
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSubIndicatorStats (_, id) {
    try {
      const response = await GETCallHelper(
        `/hq/qof/indicators/sub-indicators/practices-stats?sub_indicator=${id}`
      )
      return response.data['practices-with-stats']
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSingleSubIndicatorStats (_, id) {
    try {
      const response = await GETCallHelper(`hq/qof/indicators/sub-indicators/with-stats?sub_indicator=${id}`)
      return response.data['sub-indicator'];
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchIndicatorWithPractices (_, payload) {
    try {
      const response = await GETCallHelper(
        `hq/qof/indicators/practices-stats`,{indicator: payload?.indicatorId, page: payload?.page, practice_name: payload?.name, order_by: payload?.order_by}
      )
      return response.data['practices-with-stats']
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSubIndicatorWithPractices (_, payload) {
    try {
      const response = await GETCallHelper(
        `hq/qof/indicators/sub-indicators/stats-against-practice?practice=${payload.practiceId}&indicator=${payload.indicatorId}`
      )
      return response.data['sub-indicators']
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchAverageByMonth({ commit }, payload) {
    try {
      const response = await GETCallHelper(`hq/qof/indicators/average-by-month`, payload);
      commit('setAverageByMonth', response.data['monthly-average']);
    } catch (e) {
      handleError(e.response.data);
    }
  }
}
