export default {
  getQuestions (state) {
    return state.questions;
  },
  getActionPlans (state) {
    return state.actionPlans;
  },
  getSingleActionPlan (state) {
    return state.singleActionPlan
  }
}
