/** @format */

import rules from "@/rules";
import maChildRoutes from "./childRoutes/humanResources/maChildRoutes.js";
import maMopfRoutes from "./childRoutes/monitoringOfPracticeFunctions/maChildRoutes.js";

//Main
const MainLayout = () =>
  import("@/pages/MA/monitoringOfPracticeFunctions/MainLayout.vue");
const dashboard = () => import("@/pages/MA/dashboardManager.vue");
const MaProfile = () => import("@/pages/MA/maProfile.vue");
const MaHumanResources = () =>
  import("@/pages/MA/humanResources/humanResourcesManager.vue");

const MaCentralisedClinicalActivityDashboard = () =>
  import(
    "@/pages/MA/centralisedClinicalActivity/centralisedClinicalActivityManagerDashboard.vue"
  );

const routes = [
  //Manager dashboard screen
  {
    path: "dashboard",
    name: "ma-dashboard",
    component: dashboard,
    beforeEnter: rules.isManager,
  },
  // MANAGER PROFILE
  //Manager Profile screen
  {
    path: "profile",
    name: "ma-profile",
    query: { activeTab: "" },
    component: MaProfile,
  },
  // HUMAN RESOURCES
  //Manager Human Resources Dashboard screen
  {
    path: "humanResources",
    name: "ma-humanResources",
    component: MaHumanResources,
    children: maChildRoutes,
  },

  // CENTRALISED CLINICAL ACTIVITY
  //Manager Centralised Clinical Activity Dashboard screen
  {
    path: "centralisedClinicalActivityDashboard",
    name: "ma-centralisedClinicalActivityDashboard",
    component: MaCentralisedClinicalActivityDashboard,
  },
  // MONITORING OF PRACTICE FUNCTIONS
  //Manager Monitoring of Practice Functions Dashboard screen
  {
    path: "MoPF",
    name: "ma-main",
    component: MainLayout,
    children: maMopfRoutes,
  },
];

export default routes;
