// actions
export const POSTAPPRAISALPOLICY = 'POSTAPPRAISALPOLICY'
export const GETALLAPPRAISALPOLICIES = 'GETALLAPPRAISALPOLICIES'
export const DELETEAPPRAISALPOLICY = 'DELETEAPPRAISALPOLICY'
export const SCHEDULEAPPRAISALACTION = 'SCHEDULEAPPRAISALACTION'
export const RESCHEDULEAPPRAISALACTION = 'RESCHEDULEAPPRAISALACTION'
export const GETALLSCHEDULEDAPPRAISALACTION = 'GETALLSCHEDULEDAPPRAISALACTION'
export const FETCHSINGLEAPPRAISALACTION = 'FETCHSINGLEAPPRAISALACTION'
export const SAVEAPPRAISALANSWERACTION = 'SAVEAPPRAISALANSWERACTION'
export const SAVEOVERDUEAPPRAISALS = 'SAVEOVERDUEAPPRAISALS'
export const SAVEPENDINGAPPRAISALS = 'SAVEPENDINGAPPRAISALS'
export const SAVEUPCOMINGPPRAISALS = 'SAVEUPCOMINGPPRAISALS'
export const SAVERESCHEDULEDPPRAISALS = 'SAVERESCHEDULEDPPRAISALS'

// mutations

export const ALLAPPRAISALPOLICIESMUTATION = 'ALLAPPRAISALPOLICIESMUTATION'
export const SETLOADINGMUTATION = 'SETLOADINGMUTATION'
export const SETFETCHEDSCHEDULEDAPPRAISALSMUTATION =
  'SETFETCHEDSCHEDULEDAPPRAISALSMUTATION'
export const FETCHSINGLEAPPRAISMUTATION = 'FETCHSINGLEAPPRAISMUTATION'
export const SAVEAPPRAISALANSWERMUTATION = 'SAVEAPPRAISALANSWERMUTATION'
export const SAVEOVERDUEAPPRAISALSMUTATION = 'SAVEOVERDUEAPPRAISALSMUTATION'
export const SAVEPENDINGAPPPRAISALSMUTATION = 'SAVEPENDINGAPPPRAISALSMUTATION'
export const SAVEUPCOMINGPPPRAISALSMUTATION = 'SAVEUPCOMINGPPPRAISALSMUTATION'
export const SAVERESCHEDULEDAPPRAISALSMUTATION = 'SAVERESCHEDULEDAPPRAISALSMUTATION'


//getters

export const GETLOADING = 'GETLOADING'
export const GETAPPRAISALPOLICIESGETTER = 'GETAPPRAISALPOLICIESGETTER'
export const GETFETCHEDSCHEDULEDAPPRAISALSGETTER =
  'GETFETCHEDSCHEDULEDAPPRAISALSGETTER'
export const SINGLE_APPRAISAL_GETTER = 'SINGLE_APPRAISAL_GETTER'
export const GETAOVERDUEAPPRAISALSGETTER = 'GETAOVERDUEAPPRAISALSGETTER'
export const GETPENDINGAPPRAISALSGETTER = 'GETAOVERDUEAPPRAISALSGETTER'
export const GETPUPCOMINGAPPRAISALSGETTER = 'GETPUPCOMINGAPPRAISALSGETTER'
export const GETRESCHEDULEDAPPRAISALSGETTER = 'GETRESCHEDULEDAPPRAISALSGETTER'
