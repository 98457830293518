const UserAuth = () => import("@/pages/auth/UserAuth.vue");
const forgotPassword = () => import("@/pages/auth/forgotPassword.vue");
const resetPassword = () => import("@/pages/auth/resetPassword.vue");

const routes = [
  // Login screen
  {
    path: "/login",
    name: "gu-login",
    component: UserAuth,
  },
  // Forgot-password screen
  {
    path: "/forgot-password",
    name: "gu-forgotPassword",
    component: forgotPassword,
  },
  //reset-password screen
  {
    path: "/reset-password",
    name: "gu-resetPassword",
    component: resetPassword,
    query: { token: "", email: "" },
  },
];
export default routes;
