/** @format */

const ComplaintsDashboardMa = () =>
  import(
    "@/pages/MA/monitoringOfPracticeFunctions/Complaints/ComplaintsDashboardMa.vue"
  );

const MaViewComplaints = () =>
  import(
    "@/pages/MA/monitoringOfPracticeFunctions/Complaints/MaViewComplaints.vue"
  );

const maComplaintsRoutes = [
  {
    path: "",
    name: "ma-monitoringOfPracticeFunctionComplaintsDashboard",
    component: ComplaintsDashboardMa,
  },
  {
    path: "/:isManager/create/:isEnabled/complaint/:complaintId",
    name: "ma-monitoringOfPracticeFunctionComplaint-view",
    component: MaViewComplaints,
  },
];

export default maComplaintsRoutes;
