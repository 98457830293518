export default {
  getProfile(state) {
    return state.profile;
  },
  getContractSummary(state) {
    return state.profile?.contract_summary;
  },
  getPositionInformation(state) {
    return state.profile?.position_summary;
  },
  getWorkPatterns(state) {
    return state.workPatterns;
  },
  getWorkTimings(state) {
    return state.workTimings;
  },
  getMiscData(state) {
    return state.miscInfo;
  },
  getEmploymentChecks(state) {
    return state.employmentChecks;
  },
  getEmploymentPolicies(state) {
    return state.employmentPolicies;
  },
  getExperiencesInformation(state) {
    return state.experiencesInformation;
  },
  getReferencesData(state) {
    return state.references;
  },
  getEducationInformation(state) {
    return state.education;
  },
  getRegistrations(state) {
    return state.registrations;
  },
  getEmergencyContacts(state) {
    return state.emergencyContacts;
  },
  getTermination(state) {
    return state.termination;
  },
  getSidbarOpen(state) {
    return state.sidbarOpen;
  },
};
