/** @format */

export default {
  getPractices(state) {
    return state.practices;
  },
  getAllComplaints(state) {
    return state.allComplaints;
  },
  getComplaint(state) {
    return state.complaint;
  },

  getComplaintTypeStats(state) {
    return state.complaintTypeStats;
  },

  getSubjectGroupStats(state) {
    return state.subjectGroupStats;
  },
  getStaffGroupStats(state) {
    return state.staffGroupStats;
  },

  getAgeGroupStats(state) {
    return state.ageGroupStats;
  },

  getPatientRelationshipGroupStats(state) {
    return state.patientRelationshipGroupStats;
  },

  getComplaintsAgainstPracticesStats(state) {
    return state.complaintsAgainstPracticesStats;
  },
};
