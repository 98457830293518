// import axios from "axios";
import { handleError } from '../errorServices'
import { POSTCallHelper } from '../services.js'
export default {
  // Get Users
  async loadUsers (context, payload) {
    try {
      const response = await POSTCallHelper('users/filter', payload)
      context.commit('setUsers', response.data.users)
      return response.data.users
    } catch (e) {
      handleError(e.response.data)
      return false
    }
  },
  // Get Single User
  async loadSingleUser (context, payload) {
    try {
      const response = await POSTCallHelper('users', payload)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Create Interview
  async createInterview (context, payload) {
    try {
      const response = await POSTCallHelper('re/interviews/create', payload)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchAllInterviews (context, payload) {
    try {
      const response = await POSTCallHelper(
        're/interviews/all-interviews',
        payload
      )
      context.commit('set_all_interviews', response.data['interviews'].data)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  }
}
