/** @format */

export default {
  getComplianceTypes(state) {
    return state.complianceTypes;
  },
  getComplianceCalenderItems(state) {
    return state.complianceCalenderItems;
  },
  getSingleComplianceTypeArchives(state) {
    return state.singleComplianceTypeArchives;
  },
  getSingleComplianceItem(state) {
    return state.singleComplianceItem;
  },
  getSubmittedFilesByPractice(state) {
    return state.submittedFilesByPractice;
  },
  getPracticesForCompliance(state) {
    return state.practicesForCompliance;
  },
};
