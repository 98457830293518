export default {
  setNewHiringRequest(state, payload) {
    state.newHiringRequest = payload;
  },
  setDepartments(state, payload) {
    state.practiceDepartments = payload;
  },
  setJobSpecifications(state, payload) {
    state.jobSpecifications = payload;
  },
  setSingleJobSpecification(state, payload) {
    state.singleJobSpecifications = payload;
  },
  setPersonSpecifications(state, payload) {
    state.personSpecifications = payload;
  },
  setSinglePersonSpecification(state, payload) {
    state.singlePersonSpecification = payload;
  },
  setNewApplicationsHiringRequest(state, payload) {
    state.new_applications_hiring_request = payload;
  },
  setApprovedHiringRequest(state, payload) {
    state.approved_hiring_request = payload;
  },
  setEscalatedHiringRequest(state, payload) {
    state.escalated_hiring_request = payload;
  },
  setDeclinedHiringRequest(state, payload) {
    state.declined_hiring_request = payload;
  },
};
