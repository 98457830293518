//Main
const newHire = () => import('@/pages/MA/humanResources/newHire.vue')
const MaNewStaff = () =>
  import('@/pages/MA/humanResources/manager/maNewStaff.vue')
const MaNewStaffSummary = () =>
  import('@/pages/MA/humanResources/manager/MaNewStaffSummary.vue')
const ViewStaffHiringRequest = () =>
  import('@/pages/MA/humanResources/manager/ViewStaffHiringRequest.vue')

const ScheduleMeetings = () =>
  import('@/pages/MA/humanResources/ScheduleMeetings.vue')

const ScheduleAppraisal = () =>
  import('@/pages/MA/humanResources/ScheduleAppraisal.vue')

const staffInductions = () =>
  import('@/pages/MA/humanResources/staffInductions.vue')
const staffTraining = () =>
  import('@/pages/MA/humanResources/staffTraining.vue')
const InductionPolicies = () =>
  import('@/pages/MA/humanResources/manager/InductionPolcies.vue')
const ConductInduction = () =>
  import('@/pages/MA/humanResources/manager/conductInduction.vue')
const Appraisals = () => import('@/pages/MA/humanResources/appraisals.vue')
const CreateAppraisalPolicy = () =>
  import('@/pages/MA/humanResources/appraisals/CreateAppraisalPolicy.vue')
const ViewAppraisal = () =>
  import('@/pages/MA/humanResources/appraisals/ViewAppraisal.vue')

const ConductAppraisal = () =>
  import('@/pages/MA/humanResources/appraisals/ConductAppraisal.vue')

const CreateStaffTrainingPage = () =>
  import('@/pages/MA/humanResources/StaffTrainings/index.vue')
const ViewSingleCourse = () =>
  import('@/pages/MA/humanResources/StaffTrainings/ViewSingleCourse.vue')
const ViewSingleCourseUser = () =>
  import('@/pages/MA/humanResources/StaffTrainings/ViewSingleCourseUser.vue')

const EditExistingStaffTrainingPage = () =>
  import('@/pages/MA/humanResources/StaffTrainings/EditExistingTraining.vue')
const routes = [
  //Manager New hire screen
  {
    path: 'newHire',
    name: 'ma-new-hire',
    component: newHire,
    query: { new: '', error: '' }
  },
  // //Manager New Staff screen
  {
    path: 'newHire/newStaff',
    name: 'ma-new-staff',
    component: MaNewStaff
  },
  // //Manager New Staff Summary screen
  {
    path: 'newHire/newStaff/confirmation',
    name: 'ma-new-staff-summary',
    component: MaNewStaffSummary
  },
  {
    path: 'view-hiring-request/:id',
    name: 'ma-view-staff-hiring-request',
    component: ViewStaffHiringRequest
  },
  //Manager Induction screen
  {
    path: 'create-staff-induction',
    name: 'ma-create-staff-induction',
    component: ScheduleMeetings
  },
  {
    path: 'staff-induction',
    name: 'ma-staff-induction',
    component: staffInductions
  },
  {
    path: 'staff-induction-policies',
    name: 'ma-staff-induction-policies',
    component: InductionPolicies
  },
  {
    path: 'edit-staff-induction-policies/:id',
    name: 'ma-edit-staff-induction-policies',
    component: InductionPolicies
  },
  {
    path: 'conduct-induction-meeting/:inductionId',
    name: 'ma-conduct-induction-meeting',
    component: ConductInduction
  },
  //Manager Training screen
  {
    path: 'staffTraining',
    name: 'ma-staff-training',
    component: staffTraining
  },
  //Manager Appriasals screen
  {
    path: 'appraisals',
    name: 'ma-appraisals',
    component: Appraisals
  },
  {
    path: 'create-appraisal-policy',
    name: 'ma-create-appraisal-policy',
    component: CreateAppraisalPolicy
  },

  {
    path: 'view-appraisal/:id',
    name: 'ma-view-appraisal',
    component: ViewAppraisal
  },
  {
    path: 'schedule-appraisal',
    name: 'ma-schedule-appraisal',
    component: ScheduleAppraisal
  },
  {
    path: 'conduct-appraisal/:id',
    name: 'ma-conduct-appraisal',
    component: ConductAppraisal
  },
  {
    path: 'ma-create-new-course',
    name: 'ma-create-new-course',
    component: CreateStaffTrainingPage
  },
  {
    path: 'ma-view-a-course/:id',
    name: 'ma-view-a-course',
    component: ViewSingleCourse
  },
  {
    path: 'ma-view-a-course-user/:id',
    name: 'ma-view-a-course-user',
    component: ViewSingleCourseUser
  },
  {
    path: 'ma-edit-a-course/:id',
    name: 'ma-edit-a-course',
    component: EditExistingStaffTrainingPage
  }
]

export default routes
