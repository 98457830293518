import rules from '@/rules'
import hqChildRoutes from './childRoutes/humanResources/hqChildRoutes.js'
// import MainLayout from '@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/MainLayout.vue'
// import HeroSectionVue from '@/components/ui/MA/appraisals/HeroSection.vue'
import mopfRoutes from './childRoutes/monitoringOfPracticeFunctions/hqChildRoutes.js'
//Main

const MainLayout = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/MainLayout.vue'
  )
const dashboard = () => import('@/pages/HQ/dashboardHQ.vue')
const HqProfile = () => import('@/pages/HQ/hqProfile.vue')
const HqHumanResources = () =>
  import('@/pages/HQ/humanResources/humanResourcesHQ.vue')

const HqCentralisedClinicalActivityDashboard = () =>
  import(
    '@/pages/HQ/centralisedClinicalActivity/centralisedClinicalActivityHQDashboard.vue'
  )
const jobPersonSpec = () => import('@/pages/jobPersonSpec.vue');

const routes = [
  //Manager dashboard screen
  {
    path: 'dashboard',
    name: 'hq-dashboard',
    component: dashboard,
    beforeEnter: rules.isHQ
  },
  // MANAGER PROFILE
  //Manager Profile screen
  {
    path: 'profile',
    name: 'hq-profile',
    query: { activeTab: '' },
    component: HqProfile
  },
  // HUMAN RESOURCES
  //Manager Human Resources Dashboard screen
  {
    path: 'humanResources',
    name: 'hq-humanResources',
    component: HqHumanResources,
    children: hqChildRoutes
  },
  //HQ Job and person Spec
  {
    path: 'jobPersonSpecification',
    name: 'hq-job-person-specification',
    component: jobPersonSpec
  },

  // CENTRALISED CLINICAL ACTIVITY
  //Manager Centralised Clinical Activity Dashboard screen
  {
    path: 'centralisedClinicalActivityDashboard',
    name: 'hq-centralisedClinicalActivityDashboard',
    component: HqCentralisedClinicalActivityDashboard
  },
  // MONITORING OF PRACTICE FUNCTIONS
  //Manager Monitoring of Practice Functions Dashboard screen
  //HQ Monitoring of Practice Functions Patient survey screens
  {
    path: 'MoPF',
    name: 'hq-main',
    component: MainLayout,
    children: mopfRoutes
  },
]

export default routes
