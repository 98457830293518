import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      newHiringRequest: {},
      practiceDepartments: null,
      jobSpecifications: null,
      singleJobSpecifications: null,
      personSpecifications: null,
      singlePersonSpecification: null,
      new_applications_hiring_request: null,
      approved_hiring_request: null,
      escalated_hiring_request: null,
      declined_hiring_request: null,
    };
  },
  mutations,
  actions,
  getters,
};
