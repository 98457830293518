import { handleError } from '../../errorServices'
import { POSTCallHelper, PATCHCallHelper } from '../../services.js'

export default {
  // FETCH ALL Hiring Requests
  async fetchAllHiringRequests (context, [payload, page] = [null, null]) {
    try {
      const response = await POSTCallHelper('hiring-requests', payload, page)
      if (payload.status === null) {
        context.commit(
          'setNewApplicationsHiringRequest',
          response.data['hiring-requests']
        )
      } else if (payload.status === 'approved') {
        context.commit(
          'setApprovedHiringRequest',
          response.data['hiring-requests']
        )
      } else if (payload.status === 'pending') {
        context.commit(
          'setNewApplicationsHiringRequest',
          response.data['hiring-requests']
        )
      } else if (payload.status === 'escalated') {
        context.commit(
          'setEscalatedHiringRequest',
          response.data['hiring-requests']
        )
      } else if (payload.status === 'declined') {
        context.commit(
          'setDeclinedHiringRequest',
          response.data['hiring-requests']
        )
      }
    } catch (e) {
      console.log(e)
      handleError(e.response.data)
    }
  },
  // FETCH Hiring Request
  async fetchHiringRequest (context, payload) {
    try {
      const response = await POSTCallHelper(
        'hiring-requests/hiring-request',
        payload
      )
      context.commit('setHiringRequest', response.data['hiring-request'][0])
      return response.data['hiring-request'][0]
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Process Hiring Request
  async processHiringRequest (context, payload) {
    try {
      const response = await PATCHCallHelper(
        'hq/hiring-requests/process-hiring-request',
        payload
      )
      console.log(
        'hq/hiring-requests/process-hiring-request - RESPONSE: ',
        response
      )
      // context.commit("setHiringRequest", response.data["hiring-request"][0]);
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Single Department
  async fetchSingleDepartment (context, payload) {
    try {
      const response = await POSTCallHelper('departments/department', payload)
      context.commit('setSingleDepartment', response.data.department)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Fetch Upcoming Interviews
  async fetchUpcomingInterviews (context, payload) {
    try {
      const response = await POSTCallHelper('hq/interviews/up-coming', payload)
      context.commit(
        'setUpcomingInterviews',
        response.data['interview-schedules'].data
      )
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async filterVacancies (context, payload) {
    try {
      const response = await POSTCallHelper('re/vacancies/filter', payload)
      context.commit('setVacanciesFilter', response.data['filtered-vacancies'])
      console.log(
        'action of filterVacancies: ',
        response.data['filtered-vacancies']
      )
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async filterProfiles ({ commit }, payload) {
    try {
      const response = await POSTCallHelper('re/candidates/filter', payload)
      commit('setCandidates', response.data['filtered-candidates'])
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchAllInterviews (context, payload) {
    try {
      const response = await POSTCallHelper('hq/interviews', payload)
      context.commit('setAllInterviews', response.data.interviews)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchAllOffers (context) {
    try {
      const response = await POSTCallHelper('hq/offers')
      context.commit('setAllOffers', response.data.offers)
      return response;
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchPractices(context) {
    try {
      // const response = await GETCallHelper("practices");
      const response = [];
      context.commit("setPractices", response.data.practices);
    } catch (e) {
      handleError(e.response.data);
    }
  },
}
