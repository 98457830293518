import rules from '@/rules'
// import hqChildRoutes from "./childRoutes/hqChildRoutes.js";
//Main
const vacanciesCandidates = () =>
  import('@/pages/RE/vacanciesCandidates/vacanciesCandidates.vue')
const manageVacancy = () =>
  import('@/pages/RE/vacanciesCandidates/manageVacancy.vue')
const manageVacancyCreateUser = () =>
  import('@/pages/RE/vacanciesCandidates/manageVacancyCreateUser.vue')
const employeeDetails = () => import('@/pages/employeeDetails.vue')
const RENewOffer = () => import('@/pages/RE/vacanciesCandidates/RENewOffer.vue')
const interviewsOffers = () =>
  import('@/pages/RE/interviewOffers/interviewOffers.vue')
const createNewInterview = () =>
  import('@/pages/RE/interviewOffers/createNewInterview.vue')
const newOffers = () => import('@/pages/RE/interviewOffers/newOffers.vue')
const ammendOffer = () => import('@/pages/RE/interviewOffers/ammendOffer.vue')
const offersInbox = () => import('@/pages/RE/interviewOffers/offersInbox.vue')
const startNewInterview = () =>
  import('@/pages/RE/interviewOffers/startNewInterview.vue')
const conductInterview = () =>
  import('@/pages/RE/interviewOffers/conductInterview.vue')
const locums = () => import('@/pages/RE/locums/locums.vue')
const newHirePolicies = () =>
  import('@/pages/RE/newHirePolicies/newHirePolicies.vue')
const createNewInterviewPolicy = () =>
  import('@/pages/RE/newHirePolicies/createNewInterviewPolicy')
const ViewInterviewPolicy = () =>
  import('@/pages/RE/newHirePolicies/viewInterviewPolicy')
const ViewInterview = () => import('@/pages/RE/newHirePolicies/viewInterview')
const EmployeeHandBook = () =>
  import('@/pages/RE/policies/documents/employeeHandbook')
const ITPolicies = () => import('@/pages/RE/policies/documents/itPolicies')
const myStaff = () => import('@/pages/RE/locums/myStaff.vue')
const locumSessions = () => import('@/pages/RE/locums/locumSessions.vue')
const locumBilling = () => import('@/pages/RE/locums/locumBilling.vue')
const policyPreview = () => import('@/pages/CS/policyPreview.vue')

const routes = [
  //Recruiter Vacancies & Candidates
  {
    path: 'vacanciesCandidates',
    name: 're-vacancies-candidates',
    component: vacanciesCandidates,
    beforeEnter: rules.isRE
  },
  //Recruiter Vacancies & Candidates
  {
    path: 'vacanciesCandidates/:manageVacancy',
    name: 're-manage-vacancy',
    component: manageVacancy,
    beforeEnter: rules.isRE
  },
  //Recruiter Vacancies & Candidates
  {
    path: 'vacanciesCandidates/:manageVacancy/createUser',
    name: 're-manage-vacancy-create-user',
    component: manageVacancyCreateUser,
    beforeEnter: rules.isRE
  },
  //Recruiter Vacancies & Candidates
  {
    path: 'vacanciesCandidates/:manageVacancy/viewUser/:userId',
    name: 're-manage-vacancy-view-user',
    component: employeeDetails,
    beforeEnter: rules.isRE
  },
  //Recruiter Vacancies & Candidates & New Offer
  {
    path: 'vacanciesCandidates/:manageVacancy/:userId/newOffer',
    name: 're-manage-vacancy-new-offer',
    component: RENewOffer,
    beforeEnter: rules.isRE
  },
  //Recruiter Interviews & Offers
  {
    path: 'interviewsOffers',
    name: 're-interviews-offers',
    component: interviewsOffers,
    beforeEnter: rules.isRE
  },
  //New Offers for the applicants
  {
    path: 'newOffers',
    name: 're-new-offers',
    component: newOffers,
    beforeEnter: rules.isRE
  },
  //ammend offer page
  {
    path: 'offersInbox/:manageVacancy/:userId/ammendOffer',
    name: 're-ammend-offer',
    component: ammendOffer,
    beforeEnter: rules.isRE
  },
  //offers inbox for applicants offers which will contain the offers made in new offers
  {
    path: 'offersInbox',
    name: 're-offers-inbox',
    component: offersInbox,
    beforeEnter: rules.isRE
  },
  //Recruiter Interviews & Offers
  {
    path: 'createNewInterview',
    name: 're-create-new-interview',
    component: createNewInterview,
    beforeEnter: rules.isRE
  },
  {
    path: 'createNewInterview/:id',
    name: 're-create-new-interview-with-user',
    component: createNewInterview,
    beforeEnter: rules.isRE
  },
  //Recruiter Interviews & Offers
  {
    path: 'startNewInterview/:interviewId',
    name: 're-start-interview',
    component: startNewInterview,
    beforeEnter: rules.isRE
  },
  {
    path: 'startNewInterview/:interviewId/conduct',
    name: 're-conduct-interview',
    component: conductInterview,
    beforeEnter: rules.isRE
  },
  //Recruiter Locums
  {
    path: 'locums',
    name: 're-locums',
    component: locums,
    beforeEnter: rules.isRE
  },
  //Recruiter All New Hire Policies
  {
    path: 'newHirePolicies',
    name: 're-vnew-hire-policies',
    component: newHirePolicies,
    beforeEnter: rules.isRE
  },
  {
    path: 'createNewInterviewPolicy',
    name: 're-create-new-interview-policy',
    component: createNewInterviewPolicy,
    beforeEnter: rules.isRE
  },
  {
    path: 'viewInterviewPolicy/:interviewPolicyId',
    name: 're-view-interview-policy',
    component: ViewInterviewPolicy,
    beforeEnter: rules.isRE
  },
  {
    path: 'viewInterview/:interviewId',
    name: 're-view-interview',
    component: ViewInterview,
    beforeEnter: rules.isRE
  },
  {
    path: 'viewOffer/:offerId',
    name: 're-view-offer',
    component: ViewInterview,
    beforeEnter: rules.isRE
  },
  {
    path: 'employee-handbooks',
    name: 're-employee-hand-books',
    component: EmployeeHandBook,
    beforeEnter: rules.isRE
  },
  {
    path: 'employee-it-policies',
    name: 're-employee-it-policies',
    component: ITPolicies,
    beforeEnter: rules.isRE
  },
  {
    path: 'my-staff',
    name: 'my-staff',
    component: myStaff,
    beforeEnter: rules.isRE
  },
  {
    path: 'locum-sessions',
    name: 'locum-sessions',
    component: locumSessions,
    beforeEnter: rules.isRE
  },
  {
    path: 'locum-billing',
    name: 'locum-billing',
    component: locumBilling,
    beforeEnter: rules.isRE
  },
  // policy preview component
  {
    path: 'policies/:policyId',
    name: 'hq-policy',
    component: policyPreview,
    props: true,
    query: { policy: '' }
  }
  // MANAGER PROFILE
  //Manager Profile screen
  // {
  //   path: "profile",
  //   name: "re-profile",
  //   query: { activeTab: "" },
  //   component: HqProfile,
  // },
  // HUMAN RESOURCES
  //Manager Human Resources Dashboard screen
  // {
  //   path: "humanResources",
  //   name: "hq-humanResources",
  //   component: HqHumanResources,
  //   children: hqChildRoutes,
  // },
  //HQ Job and person Spec
  // {
  //   path: "jobPersonSpecification",
  //   name: "hq-job-person-specification",
  //   component: jobPersonSpec,
  // },

  // CENTRALISED CLINICAL ACTIVITY
  //Manager Centralised Clinical Activity Dashboard screen
  // {
  //   path: "centralisedClinicalActivityDashboard",
  //   name: "hq-centralisedClinicalActivityDashboard",
  //   component: HqCentralisedClinicalActivityDashboard,
  // },
  // MONITORING OF PRACTICE FUNCTIONS
  //Manager Monitoring of Practice Functions Dashboard screen
  // {
  //   path: "monitoringOfPracticeFunctionsDashboard",
  //   name: "hq-monitoringOfPracticeFunctionsDashboard",
  //   component: HqMonitoringOfPractuceFunctionsDashboard,
  // },
]

export default routes
