export default {
  isLoading(state){
    return state.isLoading
  },
  userName(state) {
    return state.user;
  },
  token(state) {
    return state.token;
  },
  userRoleId(state) {
    return state.roleId;
  },
  getPractices(state) {
    return state.practices;
  },
  // getInitials(state) {
  //   return state.initials;
  // },
  isAuthenticated(state) {
    return state.isLoggedIn;
  },
  userIsGeneric(state) {
    return state.user?.generic_user;
  },
  userIsLocum(state){
    return state.user?.is_locum
  }
};
