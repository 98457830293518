import {
  GETLOADING,
  GETAPPRAISALPOLICIESGETTER,
  GETFETCHEDSCHEDULEDAPPRAISALSGETTER,
  SINGLE_APPRAISAL_GETTER,
  GETAOVERDUEAPPRAISALSGETTER,
  GETPENDINGAPPRAISALSGETTER,
  GETPUPCOMINGAPPRAISALSGETTER,
  GETRESCHEDULEDAPPRAISALSGETTER
} from './constants'

export default {
  [GETLOADING] (state) {
    return state.loading
  },
  [GETAPPRAISALPOLICIESGETTER] (state) {
    return state.appraisalPolicies
  },
  [GETFETCHEDSCHEDULEDAPPRAISALSGETTER] (state) {
    return state.ScheduledAppraisals
  },
  [SINGLE_APPRAISAL_GETTER] (state) {
    return state.currentSchedule
  },
  [GETAOVERDUEAPPRAISALSGETTER] (state) {
    return state.overdueAppraisals
  },
  [GETPENDINGAPPRAISALSGETTER] (state) {
    return state.pendingAppraisals
  },
  [GETPUPCOMINGAPPRAISALSGETTER] (state) {
    return state.upcomingAppraisals
  },
  [GETRESCHEDULEDAPPRAISALSGETTER] (state) {
    return state.upcomingAppraisals
  }
}
