import { handleError } from '../../errorServices'
import { POSTCallHelper } from '../../services.js'

export default {
  // FETCH Practice Departments
  async fetchPracticeDepartaments (context, payload) {
    try {
      const response = await POSTCallHelper('departments', payload)
      context.commit('setDepartments', response.data.departments)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Job Specifications
  async fetchJobSpecifications (context, payload) {
    try {
      const response = await POSTCallHelper('job-specifications', payload)
      context.commit(
        'setJobSpecifications',
        response.data['job-specifications']
      )
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH SINGLE Job Specifications
  async fetchSingleJobSpec (context, payload) {
    try {
      const response = await POSTCallHelper(
        'job-specifications/job-specification',
        payload
      )
      context.commit(
        'setSingleJobSpecification',
        response.data['job-specification']
      )
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH Person Specifications
  async fetchPersonSpecifications (context, payload) {
    try {
      const response = await POSTCallHelper('person-specifications', payload)
      context.commit(
        'setPersonSpecifications',
        response.data['person-specifications']
      )
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // FETCH SINGLE Person Specifications
  async fetchSinglePersonSpec (context, payload) {
    try {
      const response = await POSTCallHelper(
        'person-specifications/person-specification',
        payload
      )
      context.commit(
        'setSinglePersonSpecification',
        response.data['person-specification']
      )
    } catch (e) {
      handleError(e.response.data)
    }
  },

  // FETCH Person Specifications
  async createHiringRequest (context, payload) {
    try {
      await POSTCallHelper('hiring-requests/create', payload)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchSingleHiringRequest (context, payload) {
    try {
      const response = await POSTCallHelper(
        'hiring-requests/hiring-request',
        payload
      )
      context.commit('setNewHiringRequest', response.data['hiring-request'][0])
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  // Note arguments inside array (ES6 argument destructing)
  async fetchHiringRequests (context, [payload, page]) {
    try {
      const response = await POSTCallHelper('hiring-requests', payload, page)
        context.commit(
          'setNewApplicationsHiringRequest',
          response.data['hiring-requests']
        )
    } catch (e) {
      handleError(e.response.data)
    }
  }
}
