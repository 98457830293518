const ComplimentsDashboard = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Compliments/complimentsDashboard.vue"
  );

const createViewCompliment = () =>
  import("@/components/newUi/Compliments/CreateViewCompliments.vue");

const hqComplimentsRoutes = [
  {
    path: "",
    name: "hq-monitoringOfPracticeFunctionComplimentDashboard",
    component: ComplimentsDashboard,
  },
  {
    path: "monitoringOfPracticeFunctionsComplimentsDashboard/add-new-compliment/:view/compliment/:complimentId/manager/:isManager",
    name: "hq-monitoringOfPracticeFunctionCompliment-newCompliment",
    component: createViewCompliment,
  },
];

export default hqComplimentsRoutes;
