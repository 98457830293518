export default {
  setPractices(state, payload) {
    state.practices = payload;
  },
  deletePractice(state, payload) {
    const removeIndex = state.practices.data.findIndex(
      (item) => item.id === payload
    );
    state.practices.data.splice(removeIndex, 1);
  },
  setUser(state, payload) {
    state.users = payload;
  },
  deleteUser(state, payload) {
    const removeIndex = state.users.data.findIndex(
      (item) => item.id === payload
    );
    state.users.data.splice(removeIndex, 1);
  },
  setPolicies(state, payload) {
    state.policies = payload;
  },
  deletePolicy(state, payload) {
    const removeIndex = state.policies.findIndex((item) => item.id === payload);
    state.policies.splice(removeIndex, 1);
  },
  setRoles(state, payload) {
    state.roles = payload;
  },
  deleteRole(state, payload) {
    const removeIndex = state.roles.data.findIndex(
      (item) => item.id === payload
    );
    state.roles.data.splice(removeIndex, 1);
  },
  setPermissions(state, payload) {
    state.permissions = payload;
  },
  deletePermissions(state, payload) {
    const removeIndex = state.permissions.data.findIndex(
      (item) => item.id === payload
    );
    state.permissions.data.splice(removeIndex, 1);
  },
  setRooms(state, payload) {
    state.rooms = payload;
  },
  deleteRooms(state, payload) {
    const removeIndex = state.rooms.data.findIndex(
      (item) => item.id === payload
    );
    state.rooms.data.splice(removeIndex, 1);
  },
  setReasons(state, payload) {
    state.reasons = payload;
  },
  deleteReasons(state, payload) {
    const removeIndex = state.reasons.data.findIndex(
      (item) => item.id === payload
    );
    state.reasons.data.splice(removeIndex, 1);
  },
};
