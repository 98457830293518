import { GETCallHelper, POSTCallHelper } from '../../../services'
import { handleError } from '../../../errorServices'

export default {
  async submitSurvey (_, payload) {
    try {
      const response = await POSTCallHelper('ma/patient-surveys/store-answers', payload)
      return response;
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSurveyQuestions({ commit }) {
    try {
      const response = await GETCallHelper('ma/patient-surveys/fetch-survey-questions');
      commit('setSurveyQuestions', response.data['survey-questions']);
      localStorage.setItem('survey-id', response.data['survey-id']);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchQuestionStats ({ commit }, payload) {
    try {
      const response = await GETCallHelper(`ma/patient-surveys/question-stats?practice=${payload}`);
      commit('setQuestionStats', response.data['survey-questions'].data)
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchActionPlans ({ commit }, payload) {
    try {
      const response = await GETCallHelper(`ma/patient-surveys/action-plans?practice=${payload}`);
      commit('setActionPlans', response.data['action-plans']);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchFeedbacks ({ commit }, payload) {
    try {
      let url = `ma/patient-surveys/fetch-survey-feedbacks?page=${payload?.page ? payload?.page : payload}&practice=${localStorage.getItem('practice')}`;
      if (payload?.date) {
        url += `&date=${payload?.date}`;
      }
      if (payload?.feedbackType) {
        url += `&type=${payload?.feedbackType}`;
      }
  
      const response = await GETCallHelper(url);
      commit('setSurveyFeedbacks', response.data['survey-feedbacks']);
      return response.data['survey-feedbacks'];
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchActiveSurvey ({ commit }) {
    try {
      const response = await GETCallHelper(`ma/patient-surveys/fetch-active-survey`);
      commit("setActiveSurvey", response.data['patient-survey']);
    } catch (e) {
      handleError(e.respone.data);
    }
  },

  async fetchOptionStats ({ commit }) {
    try {
      const response = await GETCallHelper(`ma/patient-surveys/options-stats?practice=${localStorage.getItem('practice')}`);
      commit('setOptionStats', response.data['survey-questions']);
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchPracticeSurveyCount ({ commit }) {
    try {
      const response = await GETCallHelper(`ma/patient-surveys/submission-count?practice=${localStorage.getItem('practice')}`);
      commit('setPracticeSurveyCount', response.data['submission-count'])
    } catch (e) {
      handleError(e.response.data)
    }
  },
  
  async fetchAllSurveys ({ commit }) {
    try {
      const response = await GETCallHelper('ma/patient-surveys')
      commit('setPatientSurveys', response.data['patient-surveys'])
      return response.data['patient-surveys'];
    } catch (e) {
      handleError(e.response.data)
    }
  },

    async downloadSurveyPDF () {
    try {
      const response = await GETCallHelper('ma/patient-surveys/generate-pdf');
      return response;
    } catch (e) {
      handleError(e.response.data)
    }
  },  

  // async downloadSurveyPDF () {
  //   try {
  //     const response = await axios.get(`hq/patient-surveys/generate-pdf?token=${localStorage.getItem("token")}`);
  //     return response;
  //   } catch (e) {
  //     handleError(e.response.data)
  //   }
  // },
  
  async fetchSingleSurvey ({ commit }, payload) {
    try {
      const response = await POSTCallHelper('ma/patient-surveys/patient-survey', {
        patient_survey: payload
      })
      commit('setPatientSurvey', response.data['patient-survey']);
      return response.data['patient-survey'];
    } catch (e) {
      handleError(e.response.data)
    }
  },
}