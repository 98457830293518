import { handleError } from '../../errorServices'
import {
  POSTCallHelper,
  DELETECallHelper,
  POSTCallWithFilesHelper,
  GETCallHelper
} from '../../services.js'

export default {
  // Fetch interview policies
  async fetchInterviewPolicies (context, payload) {
    try {
      const response = await POSTCallHelper('re/interviews/policies', payload)
      context.commit(
        'setInterviewPolicies',
        response.data['interview-policies'].data
      )
      return response;
      // console.log(response.data['interview-policies'].data);
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async storeNewInterviewPolicy (context, payload) {
    try {
      const response = await POSTCallHelper(
        're/interviews/policies/create',
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async updateInterviewQuestion (context, payload) {
    try {
      const response = await POSTCallHelper(
        're/interviews/policies/update-question',
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async deleteInterviewQuestion (context, payload) {
    try {
      const response = await DELETECallHelper(
        `re/interviews/policies/${payload}`
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchSingleInterviewPolicy (context, payload) {
    try {
      const response = await POSTCallHelper(`re/interviews/policies/policy`, {
        interview_policy: payload
      })
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async deleteInterviewPolicy (context, payload) {
    try {
      const response = await POSTCallHelper(
        `re/interviews/policies/delete`,
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },

  // employee handbook policies api calls

  async storeHandbookOrItPolicy (context, payload) {
    try {
      const response = await POSTCallWithFilesHelper(
        `re/${payload.endpoint}/create`,
        payload.form
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async getAllDifferentPolicies (context, payload) {
    try {
      const { data } = await GETCallHelper(`re/${payload}`)
      console.log(data)
      context.commit('setAllDifferentPolicies', data[payload].data)
      return data[payload].data
    } catch (e) {
      handleError(e.response.data)
    }
  }
}
