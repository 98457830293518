const actionPlanDetailPage = () => import("@/pages/HQ/actionPlanDetailPage.vue");

const AddIndicator = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/QOF/AddIndicator.vue'
  )


const CreateActionPlan = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/ActionPlans/CreateActionPlan.vue'
  )
const ViewEditQofData = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/QOF/ViewEditQofData.vue'
  )

const QofDashboard = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/QOF/QofDashboard.vue'
  )

const UploadQofData = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/QOF/UploadQOFData.vue'
  )

const SubIndicatorsDetail = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/QOF/SubIndicatorsDetail.vue'
  )

const IndicatorsDetailPage = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/QOF/IndicatorsDetail.vue'
  )

const hqQofRoutes = [
    {
        path: '',
        name: 'hq-monitoringOfPracticeFunctionsQofDashboard',
        component: QofDashboard,
      },
      {
        path: 'createActionPlan/:qof',
        name: 'hq-createActionPlan-qof',
        component: CreateActionPlan
      },
      {
        path: 'actionPlan/indicator/:indicatorId/subIndicator/:subIndicatorId/qof/:qof/practice/:practiceId',
        name: 'hq-QOF-ActionPlan',
        component: CreateActionPlan
      },
      {
        path: 'addIndicator',
        name: 'hq-addIndicator',
        component: AddIndicator
      },
      {
        path: 'monitoringOfPracticeFunctionsAddQofData/mode/:mode?/qof-entry/:id?',
        name: 'hq-add-qof-data',
        component: UploadQofData
      },
      {
        path: 'hq-qof-view-edit',
        name: 'hq-qof-view-edit',
        component: ViewEditQofData
      },
      {
        path: 'hq-subIndicator-details/subIndicator/:subIndicatorId/indicator/:indicatorId?',
        name: 'hq-subIndicator-details',
        component: SubIndicatorsDetail,
      },
      {
        path: 'hq-indicator-details/:id',
        name: 'hq-indicator-details',
        component: IndicatorsDetailPage,
      },
      {
        path: "actionPlanDetailPage/:actionPlanId/:qof/surveyId/:surveyId",
        name: "hq-actionPlan-detail-qof",
        component: actionPlanDetailPage,
      },
]

export default   hqQofRoutes