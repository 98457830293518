export default{
    getPractices(state) {
        return state.practices;
      },
      getAllCompliments(state){
        return state.allCompliments
      },
      getCompliment(state){
        return state.compliment
      }
}