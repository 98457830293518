export default {
    
    setSessions(state, payload){
      state.sessions = payload
    },

    setMonthlySessions(state, payload){
      state.monthlySessions = payload;
    },

    setTodaysSessions(state, payload){
      state.todaysSessions = payload;
    },

    setLocumProfiles(state, payload){
      state.locumProfiles = payload;
    },
    
    setUserInvites(state, payload){
      state.userInvites = payload
    },

    setRoles(state, payload){
      state.roles = payload
    },

    setLocations(state, payload){
      state.locations = payload
    },

    setBillingSessions(state, payload){
      state.billingSessions = payload
    },

    setMonthlyFilterSessions(state, payload){
      state.monthlyFilterSessions = payload
    },

    setMonthlyInvites(state, payload){
      state.monthlyLocumInvites = payload
    },

    setSessionInviteResponse(state, payload){
      state.sessionInviteResponse = payload
    },
    
  };
  