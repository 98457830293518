import { handleError } from "../errorServices";
import { GETCallHelper, POSTCallHelper } from "../services.js";

export default {
  // Get Policies
  async loadPolicies(context, payload) {
    try {
      const response = await GETCallHelper("policies", payload);
      context.commit("setPolicies", response.data.policies);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Get Signatures
  async loadSignatures(context, payload) {
    try {
      const response = await GETCallHelper("signatures", payload);
      context.commit("setSignatures", response.data.signatures);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  //Sign Policy
  async signPolicy(_, payload) {
    try {
      await POSTCallHelper("policies/sign-policy", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  // Get Rooms
  async loadRooms(context, payload) {
    try {
      let formdata = new FormData();
      formdata.append("practice", localStorage.getItem("practice"));
      const response = await POSTCallHelper("rooms", formdata, payload);
      context.commit("setRooms", response.data.rooms);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Get Reason
  async loadReasons(context) {
    try {
      const response = await GETCallHelper("reasons");
      context.commit("setReasons", response.data.reasons);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Get Checklist
  async loadChecklist(context, payload) {
    try {
      const response = await POSTCallHelper("checklists", payload);
      context.commit("setChecklist", response.data.checklists);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  async updateTask(context, payload) {
    try {
      if (payload.status === 1) {
        payload.reason = "";
        payload.is_processed = 1;
      }
      const response = await POSTCallHelper("tasks/update", payload);
      console.log("respionse: ", response.data.task);
      context.commit("updateChecklist", response.data.task);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async completeList(_, payload) {
    try {
      await POSTCallHelper("rooms/update", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  // Get Posts
  async loadPosts(context, payload) {
    try {
      const response = await GETCallHelper("communication-book", payload);
      context.commit("setPosts", response.data.posts);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  // Get Single Post
  async loadSinglePost(context, payload) {
    try {
      const response = await POSTCallHelper("communication-book/post", payload);
      context.commit("setPost", response.data.post);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // Record Post view
  async recordPostView(context, payload) {
    try {
      await POSTCallHelper("communication-book/post-view", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // New forum entry
  async newThread(context, payload) {
    try {
      const response = await POSTCallHelper(
        "communication-book/create",
        payload
      );
      context.commit("setPost", response.data.post);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // New forum comment
  async newComment(_, payload) {
    try {
      await POSTCallHelper("communication-book/comments/create", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  // New forum answer
  async newAnswer(_, payload) {
    try {
      await POSTCallHelper("communication-book/answers/create", payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },
};
