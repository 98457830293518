import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      activeInterview: null,
      adhocQuestions: [],
      candidateQuestions: [],
      errors:[],
      miscInformation: {
        salaryInformation: {
          availability: [],
        },
        notcePeriodInformation:{
        },
      },
    };
  },
  mutations,
  actions,
  getters,
};
