// import rules from "../../rules";

const managePractices = () =>
  import("@/pages/SA/practice_management/managePractices.vue");
const managePolicies = () =>
  import("@/pages/SA/policies_management/managePolicies.vue");
const manageUsers = () => import("@/pages/SA/users_management/manageUsers.vue");
const manageRoles = () => import("@/pages/SA/roles_management/manageRoles.vue");
const managePermissions = () =>
  import("@/pages/SA/premissions_management/managePermissions.vue");
const manageRooms = () => import("@/pages/SA/rooms_management/manageRooms.vue");
const manageReasons = () =>
  import("@/pages/SA/reasons_management/manageReasons.vue");
const routes = [
  // Super-Admin manage-practices screen
  {
    path: "manage-practices",
    name: "sa-manage-practices",
    component: managePractices,
  },
  // Super-Admin manage-policies screen
  {
    path: "manage-policies",
    name: "sa-manage-policies",
    component: managePolicies,
  },
  // Super-Admin manage-users screen
  {
    path: "manage-users",
    name: "sa-manage-users",
    component: manageUsers,
  },
  // Super-Admin manage-roles screen
  {
    path: "manage-roles",
    name: "sa-manage-roles",
    component: manageRoles,
  },
  // Super-Admin manage-permissions screen
  {
    path: "managePermissions",
    name: "sa-manage-permissions",
    component: managePermissions,
  },
  // Super-Admin manage-rooms screen
  {
    path: "manage-rooms",
    name: "sa-manage-rooms",
    component: manageRooms,
  },
  // Super-Admin manage-reasons screen
  {
    path: "manage-reasons",
    name: "sa-manage-reasons",
    component: manageReasons,
  },
];

export default routes;
