/** @format */

export default {
  setPractices(state, payload) {
    state.practices = payload;
  },
  setAllComplaints(state, payload) {
    state.allComplaints = payload;
  },
  setComplaint(state, payload) {
    state.complaint = payload;
  },
  refreshUpdates(state, payload) {
    state.complaint.complaint_update = payload;
  },
  grantExtension(state, payload) {
    state.complaint.complaint_acknowledgement.due_date = payload.extension_date;
    state.complaint.complaint_acknowledgement.remaining_days =
      payload.remaining_days;
  },

  setComplaintTypeStats(state, payload) {
    state.complaintTypeStats = payload;
  },

  setSubjectGroupStats(state, payload) {
    state.subjectGroupStats = payload;
  },
  setStaffGroupStats(state, payload) {
    state.staffGroupStats = payload;
  },

  setAgeGroupStats(state, payload) {
    state.ageGroupStats = payload;
  },
  setPatientRelationshipGroupStats(state, payload) {
    state.patientRelationshipGroupStats = payload;
  },

  setComplaintsAgainstPracticesStats(state, payload) {
    state.complaintsAgainstPracticesStats = payload;
  },
};
