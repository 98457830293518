<template>
  <div
    class="bg-white w-full mx-auto p-2 sm:p-4 rounded-2xl shadow-lg flex flex-col sm:flex-row gap-5 select-none"
  >
    <div class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-1 flex-col gap-3">
        <div class="bg-gray-200 w-full animate-pulse h-14 rounded-2xl"></div>
        <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
        <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
        <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
        <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
      </div>
    </div>
  </div>
</template>
