export default {

    getMonthlySessions(state){
      return state.monthlySessions;
    },

    getSessions(state){
      return state.sessions;
    },

    getTodaysSessions(state){
      return state.todaysSessions;
    },

    getLocumProfiles(state){
      return state.locumProfiles;
    },

    getUserInvites(state){
      return state.userInvites
    },

    getRoles(state){
      return state.roles
    },

    getLocations(state){
      return state.locations
    },

    getBillingSessions(state){
      return state.billingSessions
    },

    getMonthlyFilterSessions(state){
      return state.monthlyFilterSessions
    },

    getMonthlyInvites(state){
      return state.monthlyLocumInvites
    },

    getSessionInviteResponse(state){
      return state.sessionInviteResponse
    },

  };
  