import { GETCallHelper, DELETECallHelper, POSTCallHelper, PUTCallHelper } from '../../../services';
import { handleError } from '@/store/modules/errorServices';

export default {
  async fetchIndicators({ commit }) {
    try {
      const response = await GETCallHelper(`ma/qof/indicators`);
      commit('setIndicators', response.data['qof-indicators'])
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async deleteIndicator(_, payload) {
    try {
      await DELETECallHelper(`ma/qof/indicators/delete?indicator=${payload}`)
    } catch (e) {
      handleError(e.response.data);
    }
  },
  async fetchIndicatorsWithStats({ commit }, payload) {
    try {
      const response = await GETCallHelper('ma/qof/indicators/with-stats',{ practice: payload?.practiceId, order_by: payload?.order_by, name: payload?.name, page: payload?.page });
      commit('setIndicatorsWithStats', response.data.indicators);
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchSingleIndicatorStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(`ma/qof/indicators/indicator?indicator=${payload.indicator}&practice=${payload.practice}`);
      commit('setSingleIndicatorStats', response.data.indicator)
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchSubIndicatorStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(`ma/qof/indicators/sub-indicators/stats-against-practice?practice=${payload.practice}&indicator=${payload.indicator}`);
      commit('setSubIndicatorStats', response.data['sub-indicators']);
    } catch (e) {
      e.response.data;
    }
  },

  // QOF Entries Endopoints

  async createQofEntry(_, payload) {
    try {
      await POSTCallHelper('ma/qof/entries/create', payload)
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchQofEntries({ commit }, payload) {
    try {
      const response = await GETCallHelper('ma/qof/entries',{ date: payload?.date, page: payload?.page, practice: payload?.practice})
      commit('setQofEntries', response.data['qof-entries'])
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSingleQofEntry({ commit }, payload) {
    try {
      const response = await GETCallHelper(`ma/qof/entries/qof-entry?qof_entry=${payload}`);
      commit('setSingleQofEntry', response.data['qof-entry'])
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async updateQofEntry(_, payload) {
    try {
      await PUTCallHelper(`ma/qof/entries/update?qof_entry=${payload.qof_entry}&indicator=${payload.indicator}&sub_indicator=${payload.sub_indicator}&practice=${payload.practice}`, payload);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchAverageByMonth ({ commit }, payload) {
    try {
      const response = await GETCallHelper(`ma/qof/indicators/average-by-month?practice=${localStorage.getItem('practice')}`, payload);
      commit('setAverageByMonth', response.data['monthly-average']);
    } catch (e) {
      handleError(e.response.data);
    }
  }
}