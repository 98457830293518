/** @format */

export default {
  getActivities(state) {
    return state?.activities;
  },

  getInstanceRequests(state) {
    return state?.instanceRequests;
  },

  getActivity(state) {
    return state.activity;
  },

  getInstanceSubmissions(state) {
    return state?.instanceSubmissions;
  },

  getInstanceCount(state) {
    return state?.instanceCount[0];
  },
  getSingleInstance(state) {
    return state.singleInstance;
  },
  getThresholds(state) {
    return state.thresholds;
  },
  getQuestions(state) {
    return state.questions;
  },

  getPractices(state) {
    return state.practices;
  },
  getDashInstanceCount(state) {
    return state.dashInstanceCount;
  },
};
