import { handleError } from '@/store/modules/errorServices'
import { POSTCallHelper, GETCallHelper } from '@/store/modules/services.js'
import {
  SETLOADINGMUTATION,
  SETTUTORIALACTION,
  FETCH_MY_COURSES_ACTION,
  FETCH_MY_COURSES_MUTATION,
  FETCH_SINGLE_COURSE_ACTION,
  SET_CURRENT_COURSE_MUTATION,
  UPDATE_MODULE_STATUS_ACTION,
  UPDATE_LESSON_STATUS_ACTION,
  FETCH_MY_POLICIES_ACTION,
  FETCH_MY_POLICIES_MUTATION
} from './constants'
export default {
  async [SETTUTORIALACTION] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper('users/update', payload)
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [FETCH_MY_COURSES_ACTION] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await GETCallHelper(
        `/us/trainings/courses${payload ? '?page=' + payload : ''}`
      )
      context.commit(FETCH_MY_COURSES_MUTATION, response.data['user-courses'])
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [FETCH_SINGLE_COURSE_ACTION] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper(
        `/us/trainings/courses/course`,
        payload
      )
      context.commit(SET_CURRENT_COURSE_MUTATION, response.data['user-course'])
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [UPDATE_MODULE_STATUS_ACTION] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper(
        `/us/trainings/progress/module-progress`,
        payload
      )
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [UPDATE_LESSON_STATUS_ACTION] (context, payload) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await POSTCallHelper(
        `/us/trainings/progress/lesson-progress`,
        payload
      )
      context.commit(SETLOADINGMUTATION, false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  },
  async [FETCH_MY_POLICIES_ACTION] (context) {
    context.commit(SETLOADINGMUTATION, true)
    try {
      const response = await GETCallHelper(`/us/policies`)
      context.commit(SETLOADINGMUTATION, false)
      context.commit(FETCH_MY_POLICIES_MUTATION, response)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit(SETLOADINGMUTATION, false)
  }
}
