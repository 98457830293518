/** @format */

import {
  POSTCallHelper,
  GETCallHelper,
  PUTCallHelper,
} from "../../../services";
import { handleError } from "../../../errorServices";

export default {
  async createComplianceCalender(_, payload) {
    try {
      await POSTCallHelper("/hq/compliance-calendar-items/create", payload);
    } catch (e) {
      handleError(e);
    }
  },

  async fetchComplianceTypes({ commit }) {
    try {
      const response = await GETCallHelper(
        "/hq/compliance-calendar-items/compliance-types"
      );
      commit("setComplianceTypes", response.data["compliance-calendar-types"]);
    } catch (e) {
      handleError(e);
    }
  },

  async fetchComplianceCalenderItems({ commit }) {
    try {
      const response = await GETCallHelper("/hq/compliance-calendar-items");
      commit(
        "setComplianceCalenderItems",
        response.data["compliance-calendar-items"]
      );
    } catch (e) {
      handleError(e);
    }
  },

  async fetchSingleComplianceTypeArchive({ commit }, payload) {
    try {
      let response;
      if (payload?.status) {
        response = await GETCallHelper(
          `/hq/compliance-calendar-items/archives?type=${
            payload.complianceType
          }&status=${payload.status}${
            payload.page ? `&page=${payload.page}` : ``
          }`
        );
      } else {
        response = await GETCallHelper(
          `/hq/compliance-calendar-items/archives?type=${
            payload.complianceType
          }${payload.page ? `&page=${payload.page}` : ``}`
        );
      }
      commit(
        "setSingleComplianceTypeArchives",
        response.data["archive-compliance-calendar-items"]
      );
    } catch (e) {
      handleError(e);
    }
  },

  async fetchSingleComplianceItem({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `hq/compliance-calendar-items/compliance-calendar?compliance_calendar_item=${payload.complianceId}`
      );

      commit(
        "setSingleComplianceItem",
        response.data["compliance-calendar-item"]
      );
    } catch (e) {
      handleError(e);
    }
  },
  async discardComplianceItem(_, payload) {
    try {
      await PUTCallHelper(
        `/hq/compliance-calendar-items/discarded?compliance_calendar_item=${payload.complianceId}`
      );
    } catch (e) {
      handleError(e);
    }
  },

  async fetchSingleComplianceByPractice({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/compliance-calendar-items/submitted-files?compliance_calendar_item=${payload.complianceId}&practice=${payload.practiceId}`
      );
      commit("setSubmittedFilesByPractice", response.data["manager-files"]);
    } catch (e) {
      handleError(e);
    }
  },

  async fetchPaginatedComplianceByPractices({ commit }, payload) {
    try {
      let url = `/hq/compliance-calendar-items/compliance-practices?compliance_calendar_item=${payload.complianceId}`;
      if (payload.status !== null && !payload.page) {
        url += `&status=${payload.status}`;
      } else if (payload.page && payload.status != null) {
        url += `&page=${payload.page}&status=${payload.status}`;
      } else {
        url += `&page=${payload.page}`;
      }

      const response = await GETCallHelper(url);
      commit(
        "setPaginatedPracticesForCompliance",
        response.data["compliance-calendar-item-practices"]
      );
    } catch (e) {
      handleError(e);
    }
  },
};
