import { createApp, defineAsyncComponent } from 'vue'

//General Components
import Toggle from '@/components/newUi/atoms/Toggle.vue'

//General Utilities
import Toaster from '@meforma/vue-toaster'
import './axios.js'
import router from './router/router.js'
import store from './store/index.js'
import App from './App.vue'
import './assets/tailwind.css'
import moment from 'moment'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueTablerIcons from 'vue-tabler-icons'
import { ElTable, ElTableColumn } from 'element-plus'
import ElementPlus from 'element-plus'
// import all element css, uncommented next line
import 'element-plus/dist/index.css'

import TableSkeleton from '@/components/ui/baseComponents/skeletons/tableSkeleton.vue'
import mitt from 'mitt' // Import mitt
import { createVuestic } from 'vuestic-ui'
import ClickOutside from 'vue-click-outside'

import setupInterceptor from "./composables/intercepter.js"
//import 'vuestic-ui/css'
// import Echo from 'laravel-echo'

const emitter = mitt()
// Base UI
const BaseButton = defineAsyncComponent(() =>
  import('./components/ui/baseComponents/BaseButton.vue')
)
const BaseSpinner = defineAsyncComponent(() =>
  import('./components/ui/baseComponents/BaseSpinner.vue')
)
const BaseDialog = defineAsyncComponent(() =>
  import('./components/ui/baseComponents/BaseDialog.vue')
)
const heartRate = defineAsyncComponent(() =>
  import('./components/ui/baseComponents/heartRate.vue')
)
const navigationBar = defineAsyncComponent(() =>
  import('./components/ui/baseComponents/navigationBar.vue')
)
const centralCard = defineAsyncComponent(() =>
  import('./components/ui/baseComponents/centralCard.vue')
)
const mainCardHeader = defineAsyncComponent(() =>
  import('./components/ui/baseComponents/mainCardHeader.vue')
)
const BaseModal = defineAsyncComponent(() =>
  import('@/components/ui/baseComponents/modal.vue')
)
const MyPagination = defineAsyncComponent(() =>
  import('../node_modules/v-pagination-3')
)

setupInterceptor(router)
const app = createApp(App)

app
  .use(router)
  .use(ElementPlus)
  .provide('emitter', emitter)
  .use(VueTablerIcons)
  .use(Toaster, { position: 'top-right' })
  .provide('toast', app.config.globalProperties.$toast)
  .use(store)
  .use(createVuestic())
  .provide('moment', moment)
  .component('el-table', ElTable)
  .component('base-modal', BaseModal)
  .component('el-table-column', ElTableColumn)
  .component('v-select', vSelect)
  .component('base-button', BaseButton)
  .component('base-spinner', BaseSpinner)
  .component('heart-rate', heartRate)
  .component('base-dialog', BaseDialog)
  .component('central-card', centralCard)
  .component('navigation-bar', navigationBar)
  .component('main-card-header', mainCardHeader)
  .component('my-pagination', MyPagination)
  .component('table-skeleton', TableSkeleton)
  .component('vue-click-outside', ClickOutside)
  .component('chart-toggle', Toggle)
// app.mount("#app");
router.isReady().then(function () {
  app.mount('#app')
})

// window.Pusher = require('pusher-js')

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.VUE_APP_WEBSOCKETS_KEY,
//   wsHost: process.env.VUE_APP_WEBSOCKET_HOST,
//   cluster: 'mt1',
//   wsPort: 6001,
//   wssPort: 6001,
//   enabledTransports: ['ws', 'wss'],
//   forceTLS: false
// })
