import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
  namespaced: true,
  state () {
    return {
      new_applications_hiring_request: null,
      approved_hiring_request: null,
      escalated_hiring_request: null,
      declined_hiring_request: null,
      hiring_request: null,
      singleDepartment: null,
      upcomingInterviews: null,
      allInterviews: null,
      allOffers: null,
      candidates: null,
      vacancies_filtered: null,
      practices: null,
    }
  },
  mutations,
  actions,
  getters
}
