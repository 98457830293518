import { GETCallHelper, POSTCallHelper } from '../../../services'
import { handleError } from '../../../errorServices'

export default {
    async fetchAllQuestions ({ commit }) {
      try {
        const response = await GETCallHelper(
          'ma/patient-surveys/fetch-survey-questions'
        )
        commit('setAllQuestions', response.data['survey-questions'])
        return response.data['survey-questions'];
      } catch (e) {
        handleError(e.response.data)
      }
    },

    async createActionPlanQOF ( _, payload) {
        try {
          const response = await POSTCallHelper(
            'ma/qof/action-plans/create',
            payload
          )
          return response;
        } catch (e) {
          handleError(e.response.data)
        }
      },
    
    async createActionPlanPS ( _, payload) {
        try {
          const response = await POSTCallHelper(
            'ma/patient-surveys/action-plans/create',
            payload
          )
          return response;
        } catch (e) {
          handleError(e.response.data)
        }
      },

    async fetchAllActionPlans ({ commit }, payload) {
        try {
          const response = await GETCallHelper(`ma/qof/action-plans?practice=${localStorage.getItem('practice')}`, payload)
          commit('setAllActionPlans', response.data['action-plans'])
        } catch (e) {
          handleError(e.response.data)
        }
      },
    async fetchSingleActionPlan ({ commit }, payload) {
        try {
          const response = await GETCallHelper(
            'ma/qof/action-plans/action-plan',
            { action_plan: payload }
          )
          commit('setSingleActionPlan', response.data['action-plan'])
          return response.data['action-plan']
        } catch (e) {
          handleError(e.response.data)
        }
      },

    async updateActionPlanStatus ( _, payload) {
        try {
          const response = await POSTCallHelper(
            'ma/qof/action-plans/update-status',
            payload
          )
          return response;
        } catch (e) {
          handleError(e.response.data)
        }
      },
    
    async createActionPlanMeeting ( _, payload) {
        try {
          const response = await POSTCallHelper(
            'ma/qof/action-plans/create-meeting',
            payload
          )
          return response;
        } catch (e) {
          handleError(e.response.data)
        }
      },  
  }