<template>
  <div class="bg-lightTeal flex rounded-full w-36">
    <button
      :class="{ 'bg-teal text-white': psActive }"
      class="rounded-full p-2 text-sm w-1/2"
      @click="switchChart"
    >
      P.S
    </button>
    <button
      :class="{ 'bg-teal text-white': qofActive }"
      class="rounded-full p-2 text-sm w-1/2"
      @click="switchChart"
    >
      QOF
    </button>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";

const psActive = ref(false);
const qofActive = ref(true);

const emits = defineEmits(["chartSwitch"]);

const switchChart = () => {
  psActive.value = !psActive.value;
  qofActive.value = !qofActive.value;
  emits("chartSwitch", psActive);
};
</script>
