import { GETCallHelper, POSTCallHelper } from '../../../services'
import { handleError } from '../../../errorServices'

export default {
  async fetchAllQuestions ({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `hq/patient-surveys/fetch-survey-questions${ payload ? "?survey=" + payload.surveyId : ""}`,
      )
      commit('setAllQuestions', response.data['survey-questions'])
      return response.data['survey-questions'];
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async createActionPlan ( _, payload) {
    try {
      const response = await POSTCallHelper(
        'hq/patient-surveys/action-plans/create',
        payload
      )
      return response;
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async createActionPlanQof(_, payload){
    try{
      const response = await POSTCallHelper(
        'hq/qof/action-plans/create',
        payload
      )
      return response;
    }catch(e){
      handleError(e.response.data)
    }
  },

  async fetchAllActionPlans ({ commit }, payload) {
    try {
      const response = await GETCallHelper('hq/patient-surveys/action-plans', payload)
      commit('setAllActionPlans', response.data['action-plans'])
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchAllQOFActionPlans ({ commit }, payload) {
    try {
      const response = await GETCallHelper('hq/qof/action-plans', payload)
      commit('setAllActionPlans', response.data['action-plans'])
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async fetchSingleActionPlan ({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        'hq/patient-surveys/action-plans/action-plan',
        { action_plan: payload }
      )
      commit('setSingleActionPlan', response.data['action-plan'])
      return response.data['action-plan']
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async updateActionPlanStatus ( _, payload) {
    try {
      const response = await POSTCallHelper(
        'hq/patient-surveys/action-plans/update-status',
        payload
      )
      return response;
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async createActionPlanMeeting ( _, payload) {
    try {
      const response = await POSTCallHelper(
        'hq/patient-surveys/action-plans/create-meeting',
        payload
      )
      return response;
    } catch (e) {
      handleError(e.response.data)
    }
  },
}
