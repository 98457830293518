export default {
  getPracticeInterviews (state) {
    return state.practiceInterviews
  },
  getPracticeInductions (state) {
    return state.all_inductions
  },
  getCurrentPractice (state) {
    return state.practice
  },
  getLoding (state) {
    return state.loading
  },
  getInductionPolicies (state) {
    return state.induction_policies
  },
  getCurrentInduction (state) {
    return state.current_induction
  },
  getTotalInductionPolicies (state) {
    return state.total_induction_policies
  },
  getTotalInductionScheduled (state) {
    return state.total_induction_scheduled
  },
  getSingleInductionChecklist (state) {
    return state.current_induction_checklist
  }
}
