import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      sidbarOpen: true,
      profile: null,
      workPatterns: null,
      workTimings: null,
      miscInfo: null,
      employmentChecks: null,
      employmentPolicies: null,
      experiencesInformation: null,
      references: null,
      education: null,
      registrations: null,
      emergencyContacts: null,
      termination: null,
    };
  },
  mutations,
  actions,
  getters,
};
