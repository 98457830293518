export default {
  getSingleSurvey (state) {
    return state.patientSurvey
  },
  getActiveSurveys(state) {
    return state.patientSurveys.filter((s) => s.is_active == 1)
  },
  getArchivedSurveys(state) {
    return state.patientSurveys.filter((s) => s.is_active !== 1)
  },
  getPatientSurveys (state) {
    return state.patientSurveys;
  },
  getSurveyFeedbacks(state) {
    return state.feedbacks;
  },
  getQuestionsAndAnswers(state) {
    return state.questions;
  },
  getPractices(state) {
    return state.practices;
  },
  getQuestionStats(state) {
    return state.questionStats;
  },
  getOptionStats(state) {
    return state.optionStats;
  },
  getsurveyCount(state) {
    return state.surveyCount;
  }
}
