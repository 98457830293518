import {
  SETLOADINGMUTATION,
  FETCH_MY_COURSES_MUTATION,
  SET_CURRENT_COURSE_MUTATION,
  FETCH_MY_POLICIES_MUTATION
} from './constants'

export default {
  [SETLOADINGMUTATION] (state, payload) {
    state.loading = payload
  },
  [FETCH_MY_COURSES_MUTATION] (state, payload) {
    state.my_courses = payload
  },
  [SET_CURRENT_COURSE_MUTATION] (state, payload) {
    state.current_course = payload
  },
  [FETCH_MY_POLICIES_MUTATION] (state, payload) {
    state.user_policies = payload
  }
}
