/** @format */

import hqPatientSurveyRoutes from "./patientSurvey/hqPatientSurveyRoutes";
import hqQofRoutes from "./qof/hqQofRoutes";
import hqModelDayRoutes from "./modelDay/hqModelDayRoutes";
import hqComplianceRoutes from "./compliance/hqComplianceRoutes";
import hqComplaintsRoutes from "./complaints/hqComplaintsRoutes";
import hqComplimentsRoutes from "./compliments/hqComplimentsRoutes";

const HqMonitoringOfPractuceFunctionsDashboard = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/monitoringOfPracticeFunctionsHQDashboard.vue"
  );

// Patient Survey

const HqMonitoringOfPracticeFunctionsPatientSurveyView = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/PatientSurveyView.vue"
  );
// QOF

const QofDashboardView = () =>
  import("@/pages/HQ/monitoringOfPracticeFunctions/QOF/QofDashboardView.vue");

// Model Day

const ModelDayDashboardView = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/ModelDay/ModelDayDashboardView.vue"
  );

// Compliance Calendar

const ComplianceDashboardView = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Compliance/ComplianceDashboardView.vue"
  );

//Complaints

const ComplaintsDashboardView = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Complaints/HqComplaintsDashboardView.vue"
  );

// Compliments

const ComplimentsDashboardView = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/Compliments/ComplimentsDashboardView.vue"
  );

const mopfRoutes = [
  {
    path: "monitoringOfPracticeFunctionsDashboard",
    name: "hq-monitoringOfPracticeFunctionsDashboard",
    component: HqMonitoringOfPractuceFunctionsDashboard,
  },
  // Patient Survey Routes
  {
    path: "monitoringOfPracticeFunctionsPatientSurvey",
    component: HqMonitoringOfPracticeFunctionsPatientSurveyView,
    children: hqPatientSurveyRoutes,
  },

  // QOF Routes

  {
    path: "monitoringOfPracticeFunctionsQofDashboard",
    component: QofDashboardView,
    children: hqQofRoutes,
  },
  // Model day Routes

  {
    path: "monitoringOfPracticeFunctionsModelDayDashboard",
    component: ModelDayDashboardView,
    children: hqModelDayRoutes,
  },

  // Compliance Routes
  {
    path: "monitoringOfPracticeFunctionsComplianceDashboard",
    component: ComplianceDashboardView,
    children: hqComplianceRoutes,
  },

  // Complaints
  {
    path: "monitoringOfPracticeFunctionsComplaintsDashboard",
    component: ComplaintsDashboardView,
    children: hqComplaintsRoutes,
  },
  // Compliments
  {
    path: "monitoringOfPracticeFunctionsComplimentsDashboard/:isManager",
    component: ComplimentsDashboardView,
    children: hqComplimentsRoutes,
  },
];

export default mopfRoutes;
