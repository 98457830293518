import {  GETCallHelper, POSTCallHelper} from '../../../services'
import { handleError } from '../../../errorServices'

export default {
    async postComplimentMa(_,payload){
        try{
         await POSTCallHelper("/ma/compliments/create-compliment",payload)

        }
        catch(e){
            handleError(e)
        }
    },
      async fetchAllComplimentsMa({commit}, payload) {
        try{
            let url=`/ma/compliments?practice=${payload.practice}&page=${payload.page}`;
           
            if(payload.receiving_date){
                    url = url + `&receiving_date=${payload.receiving_date}`
            }
       const response = await GETCallHelper(url)
            commit("setAllCompliments", response.data['compliments'])
        }
        catch(e){
            handleError(e.response.data);
        }
      },

      async fetchSingleComplimentMa({commit}, payload){
        try{
            const response = await GETCallHelper(`/ma/compliments/single-compliment?compliment=${payload.complimentId}`)
     
            commit("setCompliment", response.data['compliment'])

        }
        catch(e){
            handleError(e.response.data);
        }
      }


}