export default {
  setUser(state, payload) {
    state.user = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setRoleId(state, payload) {
    state.roleId = payload;
  },
  setPractices(state, payload) {
    state.practices = payload;
  },
  setIsLoggedIn(state, payload) {
    state.isLoggedIn = payload;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
};
