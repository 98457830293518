export default {
  qofEntry (state, payload) {
    state.qofEntry = payload
  },
  setIndicators (state, payload) {
    state.indicators = payload;
  },
  setQofEntries(state, payload) {
    state.qofEntries = payload;
  },
  setAverageByMonth(state, payload) {
    state.averageByMonth = payload;
  }
}
