/** @format */

export default {
  setComplianceTypes(state, payload) {
    state.complianceTypes = payload;
  },

  setComplianceCalenderItems(state, payload) {
    state.complianceCalenderItems = payload;
  },

  setSingleComplianceTypeArchives(state, payload) {
    state.singleComplianceTypeArchives = payload;
  },
  setSingleComplianceItem(state, payload) {
    state.singleComplianceItem = payload;
  },
  setSubmittedFilesByPractice(state, payload) {
    state.submittedFilesByPractice = payload;
  },
  setPaginatedPracticesForCompliance(state, payload) {
    state.practicesForCompliance = payload;
  },
};
