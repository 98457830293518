import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      newUserProfileId: null,
      user: null,
      jobPostings: null,
      offers: null,
      upcomingInterviews: null,
      firstInterviews: null,
      secondInterviews: null,
      hiringRequestApplicants: null,
    };
  },
  mutations,
  actions,
  getters,
};
