export default {
  getIndicators(state) {
    return state.indicators
  },
  getIndicatorsWithStats(state) {
    return state.indicatorsWithStats
  },
  getSingleIndicatorStats(state) {
    return state.singleIndicatorStats
  },
  getQofEntries(state) {
    return state.qofEntries
  },
  getSingleQofEntry(state) {
    return state.singleQofEntry;
  },
  getSubIndicatorStats(state) {
    return state.subIndicatorStats;
  },
  getAverageByMonth(state) {
    return state.averageByMonth;
  }
}