export default {
  getPolicies(state) {
    return state.policies;
  },
  getSignatures(state) {
    return state.signatures;
  },
  getRooms(state) {
    return state.rooms;
  },
  getReasons(state) {
    return state.reasons;
  },
  getChecklists(state) {
    return state.checklists;
  },
  // Return only items with 'is.active' status
  getTasks(_, getters) {
    // if (Object.keys(getters.getChecklists).length !== 0) {
    //   return getters.getChecklists.tasks.filter((active) => active.is_active);
    // }
    return getters.getChecklists.active_tasks;
  },
  getPosts(state) {
    return state.posts;
  },
  getPost(state) {
    return state.post;
  },
  // Method-Style Access for passing arguments to getters - BECOMES: (Dynamic Getter)
  // getPost: (state) => (postId) => {
  //   return state.posts.find((x) => x.id === parseInt(postId.id, 10));
  // },
};
