import {
  SETLOADINGMUTATION,
  ALLAPPRAISALPOLICIESMUTATION,
  SETFETCHEDSCHEDULEDAPPRAISALSMUTATION,
  FETCHSINGLEAPPRAISMUTATION,
  SAVEAPPRAISALANSWERMUTATION,
  SAVEOVERDUEAPPRAISALSMUTATION,
  SAVEPENDINGAPPPRAISALSMUTATION,
  SAVEUPCOMINGPPPRAISALSMUTATION,
  SAVERESCHEDULEDAPPRAISALSMUTATION
} from './constants'

export default {
  [SETLOADINGMUTATION] (state, payload) {
    state.loading = payload
  },
  [ALLAPPRAISALPOLICIESMUTATION] (state, payload) {
    state.appraisalPolicies = payload
  },
  [SETFETCHEDSCHEDULEDAPPRAISALSMUTATION] (state, payload) {
    state.ScheduledAppraisals = payload
  },
  [SAVEOVERDUEAPPRAISALSMUTATION] (state, payload) {
    state.overdueAppraisals = payload
  },
  [SAVEPENDINGAPPPRAISALSMUTATION] (state, payload) {
    state.pendingAppraisals = payload
  },
  [FETCHSINGLEAPPRAISMUTATION] (state, payload) {
    state.currentSchedule = payload
  },
  [SAVEAPPRAISALANSWERMUTATION] (state, payload) {
    state.scheduleAnswer = payload
  },
  [SAVEUPCOMINGPPPRAISALSMUTATION] (state, payload) {
    state.upcomingAppraisals = payload
  },
  [SAVERESCHEDULEDAPPRAISALSMUTATION] (state, payload) {
    state.rescheduledAppraisals = payload
  }
}
