import { validators } from "./validator";
export default {
  setActiveInterview(state, payload) {
    state.activeInterview = payload;
  },
  setQuestionsAnswer(state, payload) {
    const questionFound =
      state.activeInterview?.interview_policies?.[0]?.questions.find(
        (q) => q.id === payload.question
      );
    if (payload.option) {
      questionFound.answer = payload.option;
      return;
    }
    if (payload.options) {
      questionFound.answer = payload.options;
      return;
    }
    questionFound.answer = payload.answer;
  },
  setSepcialQuestions(state, payload) {
    state[`${payload.type}Questions`] = payload.questions;
    return true;
  },
  setMiscInformations(state, payload) {
    const result = validators[payload.validator](payload.value);
    if (result.length) {
      return (state.errors = result.map(
        (item) =>
          `${item.replace(
            /([a-z])([A-Z])/g,
            "$1 $2"
          )} is required field, kindly fill information accordingly`
      ));
    }
    state.miscInformation[payload.name] = payload.value;
  },
};
