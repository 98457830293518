/** @format */

export default {
  setShiftActivities(state, payload) {
    state.shiftActivities = payload;
  },
  setInstance(state, payload) {
    state.instance = payload;
  },

  setAllShiftStatuses(state, payload) {
    state.allShiftStatuses = payload;
  },

  updateStatusOfShiftActivities(state, payload) {
    state.shiftActivities[0].status = payload;
  },

  updateStatusOfSubmittedShiftActivities(state, payload) {
    state.instance.status = payload;
    if (state.shiftActivities[0]) {
      state.shiftActivities[0].status = payload;
    }
  },

  updateShiftStatus(state, payload) {
    state.allShiftStatuses[payload.shiftId].status = payload.status;
  },

  setActiveShift(state, payload) {
    state.activeShift = payload;
  },
};
