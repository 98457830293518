
  const CreateSurvey = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/CreateSurvey.vue'
  )
  

const HqMonitoringOfPracticeFunctionsPatientSurvey = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/monitoringOfPracticeFunctionsPatientSurveys.vue'
  )

const CreateActionPlan = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/ActionPlans/CreateActionPlan.vue'
  )

const FeedbacksOverviewPage = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/FeedbacksOverview.vue'
  )

const QuestionsDetailPage = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/QuestionsDetailPage.vue'
  )  
const SurveyPreview = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/SurveyPreview.vue'
  )

const SurveyEntries = () =>
  import(
    '@/pages/HQ/monitoringOfPracticeFunctions/PatientSurveys/SurveyEntries.vue'
  )

  const actionPlanDetailPage = () => import("@/pages/HQ/actionPlanDetailPage.vue");

const hqPatientSurveyRoutes = [
    {
        path: '',
        name: 'hq-HqMonitoringOfPracticeFunctionsPatientSurvey',
        component: HqMonitoringOfPracticeFunctionsPatientSurvey,
      },
      {
        path: 'createSurveys',
        name: 'hq-createSurveys',
        component: CreateSurvey
      },
      {
        path: 'createActionPlan/:qof',
        name: 'hq-createActionPlan',
        component: CreateActionPlan
      },
      {
        path: 'actionPlan/question/:questionId/practice/:practiceId',
        name: 'hq-PS-ActionPlan',
        component: CreateActionPlan
      },
      {
        path: 'feedbacksOverviewPage',
        name: 'feedbacks-overview-page',
        component: FeedbacksOverviewPage
      },
      {
        path: 'questionsDetailPage/:id',
        name: 'questions-detail-page',
        component: QuestionsDetailPage
      },
      {
        path: 'hq-survey-preview',
        name: 'hq-survey-preview',
        component: SurveyPreview
      },
      {
        path: 'hq-survey-entries/:id/survey/:isActive',
        name: 'hq-survey-entries',
        component: SurveyEntries
      },
      {
        path: "actionPlanDetailPage/:actionPlanId/:qof/surveyId/:surveyId",
        name: "hq-actionPlan-detail-ps",
        component: actionPlanDetailPage,
      },
      {
        path: 'hq-survey-entries/hq-survey-preview',
        name: 'hq-survey-questions-preview',
        component: SurveyPreview
      },
]

export default  hqPatientSurveyRoutes