/** @format */

import axios from "axios";

// local url
// axios.defaults.baseURL = "https://esm-backend.test/api";

// dev URL
axios.defaults.baseURL = "https://apidev.eharleystreetadmin.com/api";

//test url
// axios.defaults.baseURL = "https://api.eharleystreetadmin.com/api";
// axios.defaults.headers.common['Accept'] = 'application/json';
// axios.defaults.headers.common['Content-Type'] = 'application/json';

// NOT BEING PASSED FOR SOME REASON
// if (localStorage.getItem("token") != null) {
//   axios.defaults.headers.common[
//     "Authorization"
//   ] = `Bearer ${localStorage.getItem("token")}`;
// }
