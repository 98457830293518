import mutations from './mutations'
import getters from './getters'
import actions from './actions'

export default {
  namespaced: true,
  state () {
    return {
      patientSurveys: [],
      patientSurvey: {},
      questions: [],
      feedbacks: [],
      practices: {},
      questionStats: [],
      optionStats: [],
      surveyCount: 0,
    }
  },
  mutations: mutations,
  actions: actions,
  getters: getters
}
