export default{
    getComplianceTypes(state){
        return state.complianceTypes;
    },
    getAllComplianceItems(state){
        return state.AllCompliances
    },
    getSingleCompliance(state){
        return state.singleCompliance
    }
    

}