/** @format */

export default {
  setAllComplaints(state, payload) {
    state.allComplaints = payload;
  },
  setComplaint(state, payload) {
    state.complaint = payload;
  },

  setComplaintTypeStats(state, payload) {
    state.complaintTypeStats = payload;
  },

  setSubjectGroupStats(state, payload) {
    state.subjectGroupStats = payload;
  },
  setStaffGroupStats(state, payload) {
    state.staffGroupStats = payload;
  },

  setAgeGroupStats(state, payload) {
    state.ageGroupStats = payload;
  },
  setPatientRelationshipGroupStats(state, payload) {
    state.patientRelationshipGroupStats = payload;
  },
};
