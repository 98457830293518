import store from './store/index.js'

import { CLEANER, MANAGER, SUPER_ADMIN, HQ, RE } from './dataObject/roles'

const rules = {
  isGuest (to, from, next) {
    if (store.getters['auth/isAuthenticated']) {
      return next('/')
    }
    next()
  },
  isLoggedIn (to, from, next) {
    if (!store.getters['auth/isAuthenticated']) {
      return next('/')
    }
    next()
  },
  isCleaner (to, from, next) {
    if (
      !store.getters['auth/isAuthenticated'] ||
      store.getters['auth/userRoleId'] != CLEANER
    ) {
      return next(`/login?redirect=${to.path}`)
    }
    if (!localStorage.getItem('practice')) {
      return next({ name: 'practice-select' })
    }
    next()
  },
  isHQ (to, from, next) {
    if (
      !store.getters['auth/isAuthenticated'] ||
      store.getters['auth/userRoleId'] != HQ
    ) {
      return next(`/login?redirect=${to.path}`)
    }
    // Redirect to Practice select when no practice stored in local storage
    // Another part of this logic located in 'homepage.vue'
    // if (!localStorage.getItem("practice")) {
    //   return next({ name: "practice-select" });
    // }
    next()
  },
  isRE (to, from, next) {
    if (
      !store.getters['auth/isAuthenticated'] ||
      store.getters['auth/userRoleId'] != RE
    ) {
      return next(`/login?redirect=${to.path}`)
    }
    // Redirect to Practice select when no practice stored in local storage
    // Another part of this logic located in 'homepage.vue'
    // if (!localStorage.getItem("practice")) {
    //   return next({ name: "practice-select" });
    // }
    next()
  },
  isManager (to, from, next) {
    if (
      !store.getters['auth/isAuthenticated'] ||
      store.getters['auth/userRoleId'] != MANAGER
    ) {
      return next(`/login?redirect=${to.path}`)
    }
    if (!localStorage.getItem('practice')) {
      return next({ name: 'practice-select' })
    }
    next()
  },
  isSuperAdmin (to, from, next) {
    if (
      !store.getters['auth/isAuthenticated'] ||
      store.getters['auth/userRoleId'] != SUPER_ADMIN
    ) {
      return next(`/login?redirect=${to.path}`)
    }

    // if (!localStorage.getItem("practice")) {
    //   return next({ name: "practice-select" });
    // }
    next()
  },
  isGenericUser (to, from, next) {
    if (
      !store.getters['auth/isAuthenticated'] ||
      !!!store.getters['auth/userIsGeneric']
    ) {
      return next(`/login?redirect=${to.path}`)
    }
    next()
  },
  isLocum (to, from, next) {
    if (
      !store.getters['auth/isAuthenticated'] ||
      !!!store.getters['auth/userIsLocum']
    ) {
      return next(`/login?redirect=${to.path}`)
    }
    next()
  },
}

export default rules
