/** @format */

const ModelDayDashboard = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/ModelDay/ModelDayDashboard.vue"
  );

const SingleShiftPracticeDetails = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/ModelDay/SingleShiftPracticeDetail.vue"
  );

const ModelDayManagerThreshold = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/ModelDay/ModelDayManagerThreshold.vue"
  );

const ModelDayAddNewQuestions = () =>
  import(
    "@/pages/HQ/monitoringOfPracticeFunctions/ModelDay/ModelDayCreateViewEditQuestions.vue"
  );

const hqModelDayRoutes = [
  {
    path: "",
    name: "hq-monitoringOfPracticeFunctionsModelDayDashboard",
    component: ModelDayDashboard,
  },
  {
    path: "monitoringOfPracticeFunctionsModelDaySinglePractice/instance/:instanceId/shift/:shiftId/practice/:practiceId",
    name: "hq-ModelDay-monitoringOfPracticeFunctionsModelDaySinglePractice",
    component: SingleShiftPracticeDetails,
  },
  {
    path: "monitoringOfPracticeFunctionsModelDayManagerThreshold",
    name: "hq-ModelDay-monitoringOfPracticeFunctionsModelDayManagerThreshold",
    component: ModelDayManagerThreshold,
  },
  {
    path: "monitoringOfPracticeFunctionsModelDayQuestions/new/:isNew/shift/:shiftId?/region/:regionId?",
    name: "hq-ModelDay-monitoringOfPracticeFunctionsModelDayAddNewQuestions",
    component: ModelDayAddNewQuestions,
  },
];

export default hqModelDayRoutes;
