import {
  SET_ALL_COURSES_MUTATION,
  SET_ALL_COURSES_FAILED_MUTATION,
  SET_LOADING_MUTATION,
  STORE_A_COURSE_MODULE_MUTATION,
  SET_CURRENT_COURSE_MUTATION,
  STORE_ALL_EMPLOYEES_WITH_COURSES,
  SET_A_SINGLE_COURSE_MUTATION,
  SET_SINGLE_USER,
  SET_ALL_USERS_TO_ENROLL_AND_UNROLL
} from './constants'

export default {
  [SET_LOADING_MUTATION] (state, payload) {
    state.loading = payload
  },
  [SET_ALL_COURSES_MUTATION] (state, payload) {
    state.courses = payload.data
    state.coursesTotal = Math.ceil(payload.total / 10) * 10
  },
  [SET_ALL_COURSES_FAILED_MUTATION] (state) {
    state.courses = []
  },
  [STORE_A_COURSE_MODULE_MUTATION] (state, payload) {
    const course = state.courses.find(item => item.id === payload.data.course)
    course ? (course.modules[payload.index] = payload.data) : null
  },
  [SET_CURRENT_COURSE_MUTATION] (state, payload) {
    state.courses.push(payload)
    state.currentCourse = payload
  },
  [STORE_ALL_EMPLOYEES_WITH_COURSES] (state, payload) {
    state.all_employees_with_courses = payload
  },
  [SET_A_SINGLE_COURSE_MUTATION] (state, payload) {
    state.current_single_course = payload
  },
  [SET_SINGLE_USER] (state, payload) {
    state.current_user = payload
  },
  [SET_ALL_USERS_TO_ENROLL_AND_UNROLL] (state, payload) {
    state.all_users_to_enroll_and_unroll = payload
  }
}
