import mutations from './mutations'
import getters from './getters'
import actions from './actions'

export default {
  namespaced: true,
  state () {
    return {
      indicators: [],
      indicatorsWithStats: [],
      singleIndicatorStats: {},
      qofEntries: [],
      singleQofEntry: {},
      subIndicatorStats: [],
      averageByMonth: {},
    }
  },
  mutations,
  actions,
  getters
}
