/** @format */

import { GETCallHelper, POSTCallHelper } from "../../../services";
import { handleError } from "../../../errorServices";

export default {
  async postCompliment(_, payload) {
    try {
      await POSTCallHelper("/hq/compliments/create-compliment", payload);
    } catch (e) {
      handleError(e);
    }
  },
  async loadPractices({ commit }) {
    try {
      const response = await GETCallHelper("hq/practices");
      commit("setPractices", response.data.practices);
      return response.data.practices;
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchAllCompliments({ commit }, payload) {
    try {
      let url = `/hq/compliments?page=${payload.page}`;

      if (payload.practice && payload.receiving_date) {
        url =
          url +
          `&practice=${payload.practice}&receiving_date=${payload.receiving_date}`;
      } else if (payload.practice && !payload.receiving_date) {
        url = url + `&practice=${payload.practice}`;
      } else if (!payload.practice && payload.receiving_date) {
        url = url + `&receiving_date=${payload.receiving_date}`;
      }
      const response = await GETCallHelper(url);
      commit("setAllCompliments", response.data["compliments"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchSingleCompliment({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/compliments/single-compliment?compliment=${payload.complimentId}`
      );

      commit("setCompliment", response.data["compliment"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },
};
