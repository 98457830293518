/** @format */

import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      allComplaints: [],
      complaint: {},
      complaintTypeStats: [],
      subjectGroupStats: [],
      staffGroupStats: [],
      ageGroupStats: [],
      patientRelationshipGroupStats: [],
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
