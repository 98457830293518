import {
  GET_LOADING_GETTER,
  GET_COURSES_GETTER,
  ADD_LESSON_BUTTON_ENABLED_GETTER,
  GET_CURRENT_COURSE_GETTER,
  GET_COURSES_TOTAL_GETTER,
  GET_ALL_EMPLOYEES_WITH_COURSES_GETTER,
  GET_A_SINGLE_COURSE_GETTER,
  SET_SINGLE_USER_GETTER,
  GET_ALL_USERS_TO_ENROLL_AND_UNROLL_GETTER
} from './constants'

export default {
  [GET_LOADING_GETTER] (state) {
    return state.loading
  },
  [GET_COURSES_GETTER] (state) {
    return state.courses
  },
  [ADD_LESSON_BUTTON_ENABLED_GETTER] (state) {
    return state.enableLessons
  },
  [GET_CURRENT_COURSE_GETTER] (state) {
    return state.currentCourse
  },
  [GET_COURSES_TOTAL_GETTER] (state) {
    return state.coursesTotal
  },
  [GET_ALL_EMPLOYEES_WITH_COURSES_GETTER] (state) {
    return state.all_employees_with_courses
  },
  [GET_A_SINGLE_COURSE_GETTER] (state) {
    return state.current_single_course
  },
  [SET_SINGLE_USER_GETTER] (state) {
    return state.current_user
  },
  [GET_ALL_USERS_TO_ENROLL_AND_UNROLL_GETTER] (state) {
    return state.all_users_to_enroll_and_unroll
  }
}
