/** @format */

export default {
  getShiftActivities(state) {
    return state.shiftActivities;
  },
  getInstance(state) {
    return state.instance;
  },

  getAllShiftStatus(state) {
    return state.allShiftStatuses;
  },

  getActiveShift(state) {
    return state.activeShift;
  },
};
