export default {
  setPolicies(state, payload) {
    state.policies = payload;
  },
  setSignatures(state, payload) {
    state.signatures = payload;
  },
  setRooms(state, payload) {
    state.rooms = payload;
  },
  setReasons(state, payload) {
    state.reasons = payload;
  },
  setChecklist(state, payload) {
    state.checklists = payload;
  },
  updateChecklist(state, payload) {
    //Find index of specific object using findIndex method.
    const objIndex = state.checklists.active_tasks.findIndex(
      (obj) => obj.id == payload.id
    );

    //Update object's data
    state.checklists.active_tasks[objIndex] = payload;
  },
  setPosts(state, payload) {
    state.posts = payload;
  },
  setPost(state, payload) {
    state.post = payload;
  },
};
