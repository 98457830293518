export default {
  setProfile(state, payload) {
    state.profile = payload;
  },
  setWorkPatterns(state, payload) {
    state.workPatterns = payload;
  },
  setWorkTimings(state, payload) {
    state.workTimings = payload;
  },
  setMiscInfo(state, payload) {
    state.miscInfo = payload;
  },
  setEmploymentChecks(state, payload) {
    state.employmentChecks = payload;
  },
  setEmploymentPolicies(state, payload) {
    state.employmentPolicies = payload;
  },
  setExperiencesInformation(state, payload) {
    state.experiencesInformation = payload;
  },
  setReferences(state, payload) {
    state.references = payload;
  },
  setEducation(state, payload) {
    state.education = payload;
  },
  setRegistrations(state, payload) {
    state.registrations = payload;
  },
  setEmergencyContactInfo(state, payload) {
    state.emergencyContacts = payload;
  },
  setTermination(state, payload) {
    state.termination = payload;
  },
  setSideBarPosition(state, payload) {
    state.sidbarOpen = payload;
  },
};
