<!-- @format -->

<template>
  <!-- Load when authentication handled -->
  <div v-if="authenticationHandled">
    <!-- <transition name="route" mode="out-in"> -->
    <!-- <router-view /> becomes whatever route meta tag is -->
    <template v-if="!isLoading">
      <component :is="layout">
        <router-view />
      </component>
    </template>
    <template v-else>
      <div class="object-center content-center mt-60">
        <heart-rate fast="false"></heart-rate>
      </div>
    </template>
    <!-- </transition> -->
  </div>
</template>

<script>
// import Echo from "laravel-echo";

export default {
  inject: ["moment"],

  data() {
    return {
      authenticationHandled: false,
      previousRoute: null,
    };
  },
  // Check token in storage
  created() {
    if (localStorage.getItem("token")) {
      this.checkUser();
      this.loadRoles();
    } else {
      this.authenticationHandled = true;
    }

    // window.Pusher = require("pusher-js");

    // const echo = new Echo({
    //   broadcaster: "pusher",
    //   key: process.env.VUE_APP_WEBSOCKETS_KEY,
    //   cluster: "mt1",
    //   forceTLS: true,
    //   wsHost: process.env.VUE_APP_WEBSOCKET_HOST,
    //   wssPort: 6001,
    //   encrypted: true,
    //   authEndpoint: process.env.VUE_APP_WEBSOCKET_AUTH_ENDPOINT,

    //   auth: {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //   },
    // });

    // Echo.private(`my-channel`)
    // .listen('TestEvent', (e) => {
    //     console.log(e);
    // });

    // echo
    //   .private(`App.Models.User.${localStorage.getItem("user_id")}`)
    //   .notification((notification) => {
    //     console.log(notification);
    //   });
  },
  methods: {
    // Token present = validate token
    async checkUser() {
      try {
        await this.$store.dispatch("auth/tryLogin");
        if (this.UserDetails) {
          this.authenticationHandled = true;
          const lastRouteName = localStorage.getItem("LS_ROUTE_KEY");
          if (lastRouteName) {
            this.$router.push({ name: lastRouteName });
          } else {
            this.$router.push({ name: "homepage" });
          }
        }
      } catch (error) {
        console.log(
          "THIS IS INSIDE APP.VUE CHECKING WHEN ITS TRIGGERED DURING AUTO LOGIN WHITE SREEN"
        );
        this.error = error.message || "Something went wrong!";
        this.$router.push({ name: "homepage" });
      }
    },
    async loadRoles() {
      try {
        await this.$store.dispatch("admin/loadRoles");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    checkLoginTime() {
      const loginTime = localStorage.getItem("loginTime");
      if (loginTime) {
        const currentTime = new Date().getTime();
        const loginTime = new Date(parseInt(loginTime) + 43200000).getTime();
        if (currentTime > loginTime) {
          this.$router.push({ path: "/" });
        }
      }
    },
    created() {
      setInterval(this.checkLoginTime, 43200000);
    },
  },

  computed: {
    UserDetails() {
      return this.$store.getters["auth/userName"];
    },
    isLoading() {
      return this.$store.getters["auth/isLoading"];
    },
    // Check if route has 'layout' meta tag
    layout() {
      let layout = null;
      for (const match of this.$route.matched) {
        if (match.meta && match.meta.layout) {
          layout = match.meta.layout;
        }
      }
      return layout || "div";
    },
    // Return user logged in status
    isLoggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
};
</script>

<style>
.ql-container .ql-editor {
  min-height: inherit;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Inter";
}
.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.25s ease-out;
}

.v-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.v-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
/* SCROLLBAR EDITS */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: rgba(255, 255, 255, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid rgba(255, 255, 255, 0);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

.icon-border {
  border: none !important;
  width: 47px !important;
  height: 47px !important;
}

.border-radius {
  border-radius: 10px !important;
}

.custom-title {
  font-size: x-large !important;
}

.custom-text {
  font-weight: lighter !important;
  color: rgba(99, 115, 129, 1) !important;
}

.popup-icon-border {
  border: none !important;
  width: 32px !important;
  height: 32px !important;
}

.popup-border {
  border-radius: 4px !important;
  border-left: 6px solid rgba(52, 211, 153, 1) !important;
}

.popup-title {
  font-weight: 500 !important;
  font-size: medium !important;
}

.popup-text {
  font-weight: lighter !important;
  color: rgba(99, 115, 129, 1) !important;
}

.custom-cancel-button {
  border: 1px solid teal !important;
  color: teal !important;
  background: transparent !important;
}

.custom-delete-title {
  font-weight: 500 !important;
  font-size: 24px !important;
}

::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;

  cursor: pointer !important;
}

::v-deep .vs__search {
  --vs-search-input-placeholder-color: rgb(148, 148, 148);
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
  color: white;
}

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}
::v-deep ul li {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 164, 153, 1);
}
.icon-bg {
  background: rgba(235, 248, 247, 0.412) !important;
}
</style>
