export default {
  fetchAllDomains (state) {
    return state.allDomains
  },
  getIndicators (state) {
    return state.indicators;
  },
  getQofEntries (state) {
    return state.qofEntries;
  },
  getAverageByMonth (state) {
    return state.averageByMonth;
  }
}
