import rules from "@/rules";

//Main
const dashboard = () => import("@/pages/CS/dashboardCleaner.vue");
const rooms = () => import("@/pages/CS/rooms.vue");
const roomChecklist = () => import("@/pages/CS/roomChecklist.vue");
const policies = () => import("@/pages/CS/policies.vue");
const policyPreview = () => import("@/pages/CS/policyPreview.vue");
const signatureList = () => import("@/pages/CS/signatureList.vue");
const communicationBook = () => import("@/pages/CS/communicationBook.vue");
const thread = () => import("@/pages/CS/thread.vue");
const routes = [
  // Cleaner dashboard screen
  {
    path: "dashboard",
    name: "cs-dashboard",
    component: dashboard,
    beforeEnter: rules.isCleaner,
  },
  // Cleaner rooms screen
  {
    path: "rooms",
    name: "cs-rooms",
    component: rooms,
  },
  // Cleaner checklist screen
  {
    path: "rooms/:roomId",
    name: "cs-room",
    component: roomChecklist,
  },
  // Cleaner policies screen
  {
    path: "policies",
    name: "cs-policies",
    component: policies,
  },
  // Cleaner policy preview screen
  {
    path: "policies/:policyId",
    name: "cs-policy",
    component: policyPreview,
    props: true,
    query: { policy: "" },
  },
  // Cleaner signature list screen
  {
    path: "signatureList",
    name: "cs-signatureList",
    component: signatureList,
  },
  // Cleaner communication book screen
  {
    path: "communicationBook",
    name: "cs-communicationBook",
    component: communicationBook,
  },
  // Cleaner thread screen
  {
    path: "communicationBook/:threadId",
    name: "cs-communicationBookItem",
    component: thread,
    query: { new: "" },
  },
];

export default routes;
