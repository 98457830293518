import { handleError } from '../errorServices'
import { POSTCallHelper } from '../services.js'

export default {
  // Fetch Practice Interviews
  async fetchPracticeInterviews (context, payload) {
    try {
      const response = await POSTCallHelper('re/interviews', payload)
      context.commit(
        'setPracticeInterviews',
        response.data['interview-schedules']
      )
    } catch (e) {
      handleError(e.response.data)
    }
  },
  async fetchInductionsByPractice (context, payload) {
    context.commit('setLoading', true)
    try {
      const response = await POSTCallHelper('induction-schedules', payload)
      context.commit(
        'setInductionsSuccess',
        response.data['induction-schedules']
      )
    } catch (e) {
      context.commit('setInductionsUnSuccess')
      handleError(e.response.data)
    }
    context.commit('setLoading', false)
  },

  async saveInductionChecklist (context, payload) {
    context.commit('setLoading', true)
    try {
      const response = await POSTCallHelper(
        'induction-checklists/create',
        payload
      )
      context.commit('setLoading', false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit('setLoading', false)
  },

  async saveInductionSchedule (context, payload) {
    context.commit('setLoading', true)
    try {
      const response = await POSTCallHelper(
        'induction-schedules/create',
        payload
      )
      context.commit('setLoading', false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit('setLoading', false)
  },

  async fetchInductionPolicies (context, payload) {
    context.commit('setLoading', true)
    try {
      const response = await POSTCallHelper('induction-checklists', payload)
      context.commit(
        'setInductionPolicies',
        response.data['induction-checklists']
      )
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit('setLoading', false)
  },
  async fetchSingleInduction (context, payload) {
    context.commit('setLoading', true)
    try {
      const response = await POSTCallHelper(
        'induction-schedules/induction',
        payload
      )
      context.commit('setCurrentInduction', response.data['induction'])
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit('setLoading', false)
  },
  async storeInductionResultSingleQuestion (context, payload) {
    context.commit('setLoading', true)
    try {
      const response = await POSTCallHelper('induction-results/create', payload)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit('setLoading', false)
  },
  async updateScheduledInduction (context, payload) {
    context.commit('setLoading', true)
    try {
      const response = await POSTCallHelper(
        'induction-schedules/update',
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit('setLoading', false)
  },
  async deleteInductionScheduled (context, payload) {
    context.commit('setLoading', true)
    try {
      const response = await POSTCallHelper(
        `induction-schedules/delete`,
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit('setLoading', false)
  },
  async deleteInductionPolicy (context, payload) {
    context.commit('setLoading', true)
    try {
      const response = await POSTCallHelper(
        `induction-checklists/delete`,
        payload
      )
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit('setLoading', false)
  },
  async fetchSingleInductionChecklist (context, payload) {
    context.commit('setLoading', true)
    try {
      const response = await POSTCallHelper(
        'induction-checklists/induction-checklist',
        payload
      )
      context.commit(
        'set-single-induction-checklist',
        response.data['induction-checklist']
      )
      context.commit('setLoading', false)
      return response
    } catch (e) {
      handleError(e.response.data)
    }
    context.commit('setLoading', false)
  }
}
