import axios from "axios";

// Common headers for requests
function headers(val = false) {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  return val ? headers.headers : headers;
}
// Call GET request. Pass URL & page number
export async function GETCallHelper(url, data, page) {
  try {
    // PAGE passed - call endpoint with page number
    if (data && page) {
      const response = await axios.get(`${url}?page=${page}`, { params: data, headers: headers(true) })
      return response;
    } else if (data) {
      const response = await axios.get(`${url}`, { params: data, headers: headers(true) })
      return response;
    } else if (page) {
      const response = await axios.get(`${url}?page=${page}`, headers());
      return response;
    } else {
      const response = await axios.get(url, headers());
      return response;
    }
  } catch (e) {
    return e;
  }
}
// Send POST request. Pass, URL, DATA, Page number
export async function POSTCallHelper(url, data, page) {
  try {
    // PAGE passed - call endpoint with page number
    if (page) {
      const response = await axios.post(`${url}?page=${page}`, data, headers());
      return response;
    } else {
      const response = axios.post(url, data, headers());
      return response;
    }
  } catch (e) {
    return e;
  }
}
export async function POSTCallWithFilesHelper(url, data) {
  try {
    const options = {
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data,
      url,
    };

    const response = await axios(options);
    return response;
  } catch (e) {
    return e;
  }
}
export async function PATCHCallHelper(url, data, page) {
  try {
    // PAGE passed - call endpoint with page number
    if (page) {
      const response = await axios.patch(`${url}?page=${page}`, data, headers());
      return response;
    } else {
      const response = axios.patch(url, data, headers());
      return response;
    }
  } catch (e) {
    return e;
  }
}
export async function PUTCallHelper(url, data, page) {
  try {
    if (page) {
      const response = await axios.put(`${url}?page=${page}`, data, headers());
      return response;
    } else {
      const response = await axios.put(url, data, headers());
      return response
    }
  } catch (e) {
    return e;
  }
}
// Send DELETE request. pass URL, DATA
export async function DELETECallHelper(url, data) {
  try {
    const response = await axios.delete(url + data, headers());
    return response;
  } catch (e) {
    return e;
  }
}
