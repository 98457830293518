import { GETCallHelper, POSTCallHelper } from '../services'
import { handleError } from '../errorServices'

export default {
  async getPublicSurveyQuestions ({ commit }, payload) {
    try {
      const response = await GETCallHelper(`pub/patient-surveys/fetch-questions/${payload}`)
      commit('setSurveyQuestions', response.data['survey-questions'])
    } catch (e) {
      handleError(e.response.data)
    }
  },

  async submitSurvey (_, payload) {
    try {
      const response = await POSTCallHelper('pub/patient-surveys/store-answers', payload)
      return response;
    } catch (e) {
      handleError(e.response.data)
    }
  }
}