export default {
  getPractices(state) {
    return state.practices;
  },
  getUsers(state) {
    return state.users;
  },
  getPolicies(state) {
    return state.policies;
  },
  getRoles(state) {
    return state.roles;
  },
  getPermissions(state) {
    return state.permissions;
  },
  getRooms(state) {
    return state.rooms;
  },
  getReasons(state) {
    return state.reasons;
  },
};
