import { handleError } from "../errorServices";
import { POSTCallHelper } from "../services.js";

export default {
  // FETCH Practice Departments
  async fetchPersonSpecifications(context, payload) {
    try {
      const response = await POSTCallHelper("person-specifications", payload);
      context.commit("setDepartments", response.data.departments);
    } catch (e) {
      handleError(e.response.data);
    }
  },
};
